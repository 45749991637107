import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from "./router/index.js";
import { storex } from "./store/index";
import GlobeFunction from "./GlobalFunctions";
import VueResource from "vue-resource";
import Auth from "./auth/Auth";
import Croppa from 'vue-croppa';
import 'vue-croppa/dist/vue-croppa.css';
import VueQuillEditor from 'vue-quill-editor'

Vue.use(VueQuillEditor, /* { default global options } */)
Vue.use(Croppa);
Vue.use(Auth);
Vue.use(VueResource);
Vue.use(GlobeFunction);
Vue.config.productionTip = false

// Vue.http.options.root = "http://127.0.0.1:8000/api/";
// Vue.http.options.root = "http://52.57.36.226/go-green-backend/public/api/";
Vue.http.options.root = "https://testapp.gogreendanmark.dk/go-green-backend/public/api/";
Vue.http.headers.common["Authorization"] = "Basic " + Vue.auth.getToken();
Vue.http.interceptors.push((request, next) => {
  var accessToken = localStorage.getItem("token");
  request.headers.set("Authorization", "Bearer " + accessToken);

  request.headers.set("Access-Control-Allow-Origin", "*");
  next();
});

new Vue({
  vuetify,
  router,
  store: storex.state,
  render: h => h(App)
}).$mount('#app')

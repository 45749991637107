<template>
  <v-container fluid>
    <v-row
      class="text-center"
      :class="{ 'px-16': $vuetify.breakpoint.lgOnly }"
      align="center"
    >
      <!-- :style="{ height: windowHeight - 100 + 'px' }" -->
      <v-col class="text-left mb-2 mt-4" cols="6" align-self="start">
        <h3 class="text-uppercase font-weight-medium subColorTxt">
          Categories
        </h3>
      </v-col>
      <v-col class="text-right mb-2 mt-4" cols="6" align-self="start">
        <h3 class="text-uppercase font-weight-medium subColorTxt">
          Total : {{ totalCount }}
        </h3>
      </v-col>
      <v-col cols="12" md="3" xs="12">
        <v-card
          justify="center"
          class="borderR15 mainDarkColorBg pa-5"
          elevation="0"
        >
          <v-row>
            <v-col cols="12">
              <v-btn
                @click="addCateModal = true"
                fab
                class="subColorBg pa-6"
                elevation="0"
              >
                <v-avatar size="30px">
                  <img src="./../assets/icon/Component 3.svg" alt="" />
                </v-avatar>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        class="text-left"
        cols="12"
        md="3"
        xs="12"
        v-for="cate in categoryList"
        :key="cate.id"
      >
        <v-card
          v-if="cate.name != 'Default'"
          class="whiteTxt hoverClass borderR15 mainDarkColorBg pa-5"
          elevation="0"
        >
          <v-row>
            <v-col cols="12">
              <h3 class="font-weight-medium">
                <v-btn icon outlined class="subColorTxt">
                  <v-icon medium>{{ cate.icon }}</v-icon>
                </v-btn>

                {{ cate.name }}
              </h3>
            </v-col>
            <v-col class="text-right" cols="12">
              <v-btn
                @click="confirmDelete(cate.id)"
                fab
                x-small
                class="red white--text mr-3"
                elevation="0"
              >
                <v-icon>mdi-delete-forever</v-icon>
              </v-btn>
              <v-btn
                @click="editCate(cate)"
                fab
                x-small
                class="subColorBg mr-3"
                elevation="0"
              >
                <v-avatar size="18px">
                  <img src="./../assets/icon/Icon material-edit.svg" alt="" />
                </v-avatar>
              </v-btn>

              <v-btn
                @click="openCategory(cate)"
                fab
                x-small
                class="subColorBg"
                elevation="0"
              >
                <v-avatar size="18px">
                  <img
                    src="./../assets/icon/Icon awesome-angle-right.svg"
                    alt=""
                  />
                </v-avatar>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12">
        <pagination v-bind:paginationFor="paginationFor"></pagination>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="deleteConfirm" max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            Do you want to delete this Category?
          </v-card-title>

          <v-card-text>
            When you click on 'DELETE' button this category and its sub
            categories and also created companies below this category will
            permenantly removed from this app!
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="grey darken-1" text @click="deleteConfirm = false">
              cancel
            </v-btn>

            <v-btn color="red darken-1" text @click="deleteCate()">
              delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="addCateModal" persistent max-width="400px">
        <v-card class="pa-4">
          <v-row class="text-center">
            <v-col>
              <h1>
                <span v-if="cateEdit"> Edit </span>
                <span v-else>Add new</span>
                category
              </h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3 class="mb-3">Name of category</h3>
              <v-text-field
                dense
                v-model="CateName"
                flat
                label="Name of category"
                placeholder="abc"
                solo
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <h3>Select an icon from list</h3>
              <p class="mb-3 f10 grayTxt">
                (If icon not in this list, please copy an icon name from
                <a
                  target="_blank"
                  href="https://pictogrammers.github.io/@mdi/font/5.4.55/"
                  >Mdi-Icons</a
                >
                )
              </p>

              <v-combobox
                v-model="selectedIcon"
                :items="cateIconList"
                label="Select an icon"
                item-text="icon"
                item-value="item"
                solo
              >
                <template slot="item" slot-scope="data">
                  <v-icon class="mr-3">{{ data.item.icon }}</v-icon>
                  <span class="cb-item">{{ data.item.icon }}</span>
                </template>
              </v-combobox>
              <!-- <v-select  v-model="icon" flat item-text="name" :items="categoryList" label="Icon name" solo></v-select> -->
            </v-col>
            <v-col cols="12">
              <h4>Paste copied icon from web site</h4>
              <p class="mb-3 f10 grayTxt">
                (Leave blank if selected an icon from list above)
              </p>

              <v-text-field
                dense
                v-model="copiedIcon"
                flat
                label="Paste icon name here"
                placeholder="mdi-icon-name"
                solo
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <h3>Pick a colour for category</h3>

              <colour-picker
                v-model="colour"
                :value="colourVal"
                label="Pick Colour"
                picker="chrome"
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="subColorTxt" text @click="cancelCate()">
              cancel
            </v-btn>
            <v-btn
              v-if="cateEdit"
              class="subColorBg whiteTxt"
              text
              @click="updateCategory()"
            >
              update
            </v-btn>
            <v-btn
              v-else
              class="subColorBg whiteTxt"
              text
              @click="addCategory()"
            >
              add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import GlobalFunctions from "./../GlobalFunctions.js";
import ColourPicker from "vue-colour-picker";

import Pagination from "./sub_components/Pagination.vue";
export default {
  mixins: [GlobalFunctions],
  name: "LoginScreen",

  data: () => ({
    selectedIcon: "",
    copiedIcon: "",
    paginationFor: "cates",
    colour: "#000000",
    colourVal: "",
    cateEdit: false,
    select: "",
    CateName: "",
    cateIcon: "",
    addCateModal: false,
    deleteConfirm: false,
    selectedCateid: "",
    titles: [
      "Food and Beverage",
      "Design & Lifestyle",
      "Accommodation",
      "Spa & BeautY",
      "Transport",
      "Services",
      "Experiances",
    ],
  }),
  watch: {
    colour: function () {
      this.colourVal = this.colour;
    },
  },
  methods: {
    updateCategory() {
      this.callLoading(true);

      var selectedIcon;
      // check icon
      if (this.copiedIcon == "") {
        if (this.selectedIcon == "") {
          alert("Please select an icon for this menu!");
        } else {
          selectedIcon = this.selectedIcon.icon;
        }
      } else {
        selectedIcon = this.copiedIcon;
      }

      var data = {
        id: this.select.id,
        name: this.CateName,
        icon: selectedIcon,
        color: this.colour,
      };
      this.$http
        .post("updateCategory", data)
        .then((res) => {
          console.log(res.body);
          this.addCateModal = false;
          this.callAlert(true, "success", "Category updated!");
          this.callLoading(false);
          this.getCategories(1);
        })
        .catch((err) => {
          this.callAlert(
            true,
            "error",
            "Category updated not success! Please try again"
          );
          this.callLoading(false);
          console.log(err.body);
        });
      console.log(data);
    },
    cancelCate() {
      this.select = "";
      this.CateName = "";
      this.cateEdit = false;
      this.colour = "";
      this.addCateModal = false;
    },
    deleteCate() {
      var data = {
        id: this.selectedCateid,
      };
      this.$http
        .post("deleteCategory", data)
        .then((res) => {
          this.deleteConfirm = false;
          this.callAlert(true, "success", "Category removed!");
          this.getCategories(1);
          console.log(res.body);
        })
        .catch((err) => {
          this.callAlert(true, "error", "Category remove error! Try again.");
          console.log(err.body);
        });
    },
    confirmDelete(id) {
      this.deleteConfirm = true;
      this.selectedCateid = id;
    },
    editCate(cate) {
      console.log("edit start");
      console.log(cate.color);

      this.cateEdit = true;
      this.select = cate;
      this.selectedIcon = cate.icon;
      this.CateName = cate.name;
      this.colour = cate.color;
      this.addCateModal = true;
      console.log(this.select);
    },
    getselected(d) {
      console.log(d);
    },
    addCategory() {
      this.callLoading(true);
      var selectedIcon;
      console.log(this.selectedIcon);
      // check icon
      if (this.copiedIcon == "") {
        if (this.selectedIcon == "") {
          alert("Please select an icon for this menu!");
        } else {
          selectedIcon = this.selectedIcon.icon;
        }
      } else {
        selectedIcon = this.copiedIcon;
      }

      var data = {
        name: this.CateName,
        icon: selectedIcon,
        color: this.colour,
      };
      this.$http
        .post("createCategory", data)
        .then((res) => {
          console.log(res.body);
          this.select = "";
          this.selectedIcon = "";
          this.CateName = "";
          this.colour = "";
          this.cancelCate();
          this.callAlert(true, "success", "New category added!");
          this.callLoading(false);
          this.getCategories(1);
        })
        .catch((err) => {
          this.callAlert(true, "error", "Category add error! Please try again");
          this.callLoading(false);
          console.log(err.body.error);
        });

      console.log(data);
    },
    openCategory(cate) {
      this.$store.selectedCate = cate;
      this.$store.moreSelectedcate.push(cate);
      this.$router.push("/cate_view");
    },
  },
  created: {},
  components: {
    "colour-picker": ColourPicker,
    pagination: Pagination,
  },
  computed: {
    windowHeight() {
      return this.$store.windowHeight;
    },
    totalCount() {
      return this.$store.totalCount;
    },
    selectedCate() {
      return this.$store.selectedCate;
    },
    categoryList() {
      return this.$store.categoryList;
    },
    cateIconList() {
      return this.$store.cateIconList;
    },
  },
};
</script>

<template>
  <v-app>
    <!-- web view top bar -->
    <v-app-bar
      app
      color="mainLightColorBg"
      dark
      elevation="0"
      height="100px"
      class="px-16"
      v-if="$vuetify.breakpoint.lgOnly"
    >
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        color="subColorBg"
      ></v-progress-linear>
      <div
        v-if="$route.fullPath === '/'"
        class="font-weight-medium text-uppercase"
      >
        Admin login
      </div>
      <div v-else class="align-center font-weight-medium text-uppercase">
        go green admins
      </div>

      <v-row v-if="$route.fullPath !== '/'" justify="center">
        <v-col
          v-for="(tab, index) in tabs"
          :key="index"
          @click="selectTab(index, tab.path)"
          :class="{ activeTabClass: activeTab === index }"
          class="text-center alignTabs"
        >
          <v-btn icon>
            <v-avatar tile size="30px">
              <img :src="tab.icon" alt="" />
            </v-avatar>
            <br />
          </v-btn>
          <br />
          <span class="f12"> {{ tab.name }} </span>
        </v-col>
      </v-row>
      <v-btn
        v-if="$route.fullPath !== '/'"
        @click="logout()"
        elevation="0"
        text
        small
        class="text-capitalize"
      >
        <v-avatar tile size="16px" class="mr-1">
          <img src="./assets/icon/Icon open-account-logout.svg" alt="" />
        </v-avatar>
        Logout
      </v-btn>
    </v-app-bar>
    <v-app-bar
      v-else
      app
      color="mainLightColorBg"
      dark
      elevation="0"
      height="100px"
    >
      <div
        v-if="$route.fullPath === '/'"
        class="d-flex align-center font-weight-medium text-uppercase"
      >
        Admin login
      </div>

      <v-row v-if="$route.fullPath !== '/'" justify="space-around">
        <v-col
          v-for="(tab, index) in tabs"
          :key="index"
          @click="selectTab(index, tab)"
          :class="{ activeTabClass: activeTab === index }"
          class="text-center"
          cols="3"
        >
          <v-btn icon>
            <v-avatar tile size="30px">
              <img :src="tab.icon" alt="" />
            </v-avatar>
            <br />
          </v-btn>
          <br />
          <span class="f12"> {{ tab.name }} </span>
        </v-col>
        <v-col class="text-center" cols="3">
          <v-btn
            @click="logout()"
            v-if="$route.fullPath !== '/'"
            elevation="0"
            icon
            class="text-capitalize"
          >
            <v-avatar tile size="30px">
              <img src="./assets/icon/Icon open-account-logout.svg" alt="" />
            </v-avatar>
            <br />
          </v-btn>
          <br />
          <span class="f12"> Logout </span>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-main class="mainColorBg">
      <v-alert
        dismissible
        class="whiteBg alignAlert"
        transition="slide-x-reverse-transition"
        :value="alertModal.status"
        :type="alertModal.NotiType"
        width="auto"
      >
        {{ alertModal.text }}
      </v-alert>
     
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>


import GlobalFunctions from "./GlobalFunctions.js";
export default {
  mixins: [GlobalFunctions],
  name: "App",



  data: () => ({
    tabactivated: false,
    errorClass: "errorClass",
    activeTab: "",
    tabs: [
      {
        name: "Dashboard",
        icon: require("./assets/icon/Icon material-dashboard.svg"),
        path: "/dashboard",
      },
      {
        name: "Companies",
        icon: require("./assets/icon/Icon awesome-shopping-bag.svg"),
        path: "/companies",
      },
      {
        name: "Categories",
        icon: require("./assets/icon/Icon ionic-md-list-box.svg"),
        path: "/categories",
      },
    ],
  }),
  watch: {
    $route: function () {
      if (this.$route.fullPath === "/dashboard") {
        this.activeTab = 0;
        this.checkAuth();
      }
      if (this.$route.fullPath === "/categories") {
        this.activeTab = 2;
        this.getCategories(1);
      }
      if (this.$route.fullPath === "/companies") {
        this.activeTab = 1;
      }
    },
  },
  created() {
    // this.checkAuth();
  },
  methods: {
    selectTab(index, tab) {
      console.log(tab);
      this.activeTab = index;
      this.$router.push(tab);
    },
  },
  computed: {
    alertModal() {
      return this.$store.alertModal;
    },
    loading() {
      return this.$store.loading;
    },
  },
};
</script>
<style scoped>
@import url("./css/main.css");
</style>

import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export const storex = new Vuex.Store({
  state: {
    // pagination: {
    //   length: 5,
    //   page: 1,
    // },
    pages: '',
    menus: '',
    pageEdit: '',
    pagi_length: 0,
    pagi_current_page: 0,
    totalCount: 0,
    windowHeight: window.innerHeight,
    selectedCate: "",
    moreSelectedcate: [],
    moreSelectedSubcate: [],
    dialog: false,
    companyEditEnable: false,
    placeData: {
      name: "",
      image: null,
      description: "",
      category: [],
      url: "",
      status: "publish",
      address: [
        {
          name: "",
          address: "",
        },
      ],
    },
    // selectMoreCateList: [],

    loading: false,
    alertModal: {
      status: false,
      type: "",
      text: "",
    },
    companies: "",
    categoryList: "",
    cateIconList: "",
    companyDeleteConfirm: false,
    selectedCompany: "",
    selectedSubCate: "",
  },
});

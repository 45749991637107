
export default {
  methods: {
    isAuthenticated() {
      var token = localStorage.getItem("token");
      if (token) {
        return true;
      } else {
        return false;
      }
    },
    logout() {
      localStorage.clear();
      this.$router.push("/");
    },
    checkAuth() {
      if (this.isAuthenticated()) {
        this.$router.push("/dashboard");
      } else {
        this.$router.push("/");
      }
    },
    callLoading(value) {
      this.$store.loading = value;
    },
    callAlert(status, type, text) {
      this.$store.alertModal.status = status;
      this.$store.alertModal.NotiType = type;
      this.$store.alertModal.text = text;
      var self = this;
      setTimeout(function () {
        self.$store.alertModal.status = false;
      }, 5000);
    },
    createPage(pageData) {
      var data = pageData
      this.$http
        .post("createPage", data)
        .then((res) => {

          console.log(res.body)
        }).then(err => {
          console.log(err)
        })
      console.log(data)


    },
    pageUpdate(pageData) {
      var data = pageData
      this.$http
        .post("updatePage", data)
        .then((res) => {

          console.log(res.body)
        }).then(err => {
          console.log(err)
        })
      console.log(data)


    },
    deletePage(pageId) {
      var data = {
        'id': pageId
      }
      this.$http
        .get("deletePage", data)
        .then((res) => {
          console.log(res)
        }).then(err => {
          console.log(err)
        })
      console.log(data)
    },
    getAllPages() {
      var data = {
        avoid_paginate: false
      }
      this.$http
        .post("viewAllPage", data)
        .then((res) => {
          this.$store.pages = res.body

          console.log(res.body)
          return this.$store.pages
        }).then(err => {
          console.log(err)
        })
      console.log(data)
    },
   async getMenus() {
      var data = {
        avoid_paginate: true
      }
      this.$http
        .post("viewMenu", data)
        .then((res) => {
          this.$store.menus = res.body;
          
          console.log(res.body)
          // return Promise.res.body
        }).then(err => {
          console.log(err)
        })

    },
   
    getCategories(pageNo) {
      this.$store.totalCount = 0;
      this.$store.pagi_length = 0;

      this.callLoading(true);
      this.$http
        .post("viewCategory?page=" + pageNo)
        .then((res) => {
          this.$store.categoryList = res.body.data;
          this.$store.totalCount = res.body.total;
          this.$store.pagi_length = res.body.last_page;
          this.$store.pagi_current_page = res.body.current_page;
          let def = {
            name: "Default",
            icon: "mdi-decagram",
          };
          let arrayDef = [];
          arrayDef.push(res.body.data);
          arrayDef[0].push(def);

          let mainIcons = [
            {
              icon: "mdi-bag-checked",
            },
            {
              icon: "mdi-food",
            },
            {
              icon: "mdi-pencil-ruler",
            },
            {
              icon: "mdi-home-search",
            },
            {
              icon: "mdi-bed",
            },
            {
              icon: "mdi-spa",
            },
            {
              icon: "mdi-bicycle",
            },
            {
              icon: "mdi-office-building",
            },
            {
              icon: "mdi-flower-poppy",
            },
            {
              name: "Default",
              icon: "mdi-decagram",
            },
          ];
          this.$store.cateIconList = arrayDef[0];
          this.$store.cateIconList = mainIcons;
          this.callLoading(false);
        })
        .catch((err) => {
          this.callLoading(false);
          console.log(err);
        });
    },

    openDialog() {
      this.$store.dialog = true;
    },
    getAllCompanies(pageNo) {
      // this.$store.pagi_current_page = 0;
      this.$store.pagi_length = 0;
      this.$store.totalCount = 0;
      this.$store.companies = "";
      this.callLoading(true);
      this.$http
        .post("viewPlace?page=" + pageNo)
        .then((res) => {
          this.$store.companies = res.body.data;
          this.$store.totalCount = res.body.total;
          this.$store.pagi_length = res.body.last_page;
          this.$store.pagi_current_page = res.body.current_page;
          this.callLoading(false);
          // this.callAlert(true,"success","New company added.")
        })
        .catch((err) => {
          this.callLoading(false);
          console.log(err.body);
        });
    },
    getCompanies(cateId, text, pageNo) {
      this.$store.pagi_length = 0;
      this.$store.totalCount = 0;
      this.$store.companies = "";
      this.callLoading(true);
      var data = {
        category_id: [cateId],
        name: text,
      };
      this.$http
        .post("auth/viewCategoryPlace?page=" + pageNo, data)
        .then((res) => {
          this.$store.companies = res.body.data;
          this.$store.totalCount = res.body.total;
          this.$store.pagi_length = res.body.last_page;
          this.$store.pagi_current_page = res.body.current_page;
          this.callLoading(false);
          // this.callAlert(true,"success","New company added.")
        })
        .catch((err) => {
          this.callLoading(false);
          this.callAlert(true, "warning", "Companies not found!");
          console.log(err.body);
        });
    },
    addmoreCate(cate, sub) {
      this.cardLoading = true;
      var exists = this.$store.moreSelectedcate.some(function (field) {
        return field.id === cate.id;
      });
      if (!exists) {
        this.$store.moreSelectedcate.push(cate);
      }
      var Aexists = this.$store.moreSelectedSubcate.some(function (fieldz) {
        return fieldz.id === sub.id;
      });
      if (!Aexists) {
        if (this.$store.moreSelectedSubcate.push(sub)) {
          this.callAlert(true, "success", "Category selected!");
          this.cardLoading = false;
          this.menu = false;
        }
      } else {
        this.callAlert(true, "error", "Already selected!");
        this.cardLoading = false;
      }
    },
    filterCompany(name) {
      this.$store.companies = "";
      this.callLoading(true);
      var data = {
        name: name,
      };
      this.$http
        .post("auth/viewPlace", data)
        .then((res) => {
          this.$store.companies = res.body.data;
          this.callLoading(false);
          // this.callAlert(true,"success","New company added.")
        })
        .catch((err) => {
          this.callLoading(false);
          console.log(err.body);
        });
    },

    deleteCompany() {
      this.callLoading(true);
      var data = {
        id: this.$store.selectedCompany.id,
      };
      this.$http
        .post("deletePlace", data)
        .then((res) => {
          this.$store.companyDeleteConfirm = false;
          this.callAlert(true, "success", "Company removed!");
          this.getCompanies(this.$store.selectedSubCate.id, "", 1);
          console.log(res.body);
        })
        .catch((err) => {
          this.callAlert(true, "error", "Company remove error! Try again.");
          console.log(err.body);
          this.callLoading(false);
        });
    },
  },
};

<template>
  <v-container fluid>
    <div class="text-center">
      <v-pagination
        color="subColorBg"
        v-model="page"
        :paginationFor="paginationFor"
        :length="pagi_length"
        circle
      ></v-pagination>
    </div>
  </v-container>
</template>
<script>
import GlobalFunctions from "./../../GlobalFunctions.js";
export default {
  mixins: [GlobalFunctions],
  name: "Pagination",
  props: {
    paginationFor: {
      type: String,
    },
  },
  mounted() {
    this.page = this.pagi_current_page;
    // alert(this.length);
  },
  data() {
    return { page: "" };
  },
  methods: {},
  watch: {
    pagi_current_page: function () {
      this.page = this.$store.pagi_current_page;
    },
    page: function () {
      if (this.paginationFor == "companies") {
        this.getAllCompanies(this.page);
      }
      if (this.paginationFor == "cates") {
        this.getCategories(this.page);
      }
      if (this.paginationFor == "cate_view") {
        this.getCompanies(this.selectedSubCate.id, "", this.page);
      }
      this.$store.pagi_current_page = this.page;
    },
  },
  computed: {
    pagi_length() {
      return this.$store.pagi_length;
    },
    pagi_current_page() {
      return this.$store.pagi_current_page;
    },
    selectedSubCate() {
      return this.$store.selectedSubCate;
    },
  },
};
</script>

<template>
  <v-container id="map" class="pa-0">
    <div>
      <v-card class="pa-9">
        <v-row>
          <v-col cols="6">
            <v-autocomplete
              label="Add address"
              :search-input.sync="TypedSearch"
              :items="results"
              :loading="isLoading"
              clearable
              solo
              hide-no-data
              hide-selected
              item-text="PlaceName"
              item-value="index"
              return-object
              v-model="selectedAddress"
            ></v-autocomplete>
            <h1>needed</h1>
            <p v-if="selectedAddress != null">
              {{ selectedAddress.place_name }} <br />
              {{ selectedAddress.geometry }} <br />
            </p>
            <pre>
                {{ selectedAddress }}
            </pre>
          </v-col>
          <v-col cols="6">
            <pre>
              {{ results }}
            </pre>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div></div>
  </v-container>
</template>

<script>
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from "mapbox-gl";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

export default {
  name: "map",
  data() {
    return {
      geocoder: "",

      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      selectedAddress: null,
      // map: '',
      TypedSearch: null,
    };
  },
  components: {},
  watch: {
    TypedSearch: function () {
      // Items have already been loaded
      // if (this.results.length > 0) return;

      // Items have already been requested
      // if (this.isLoading) return;

      this.isLoading = true;

      const byteArray = encodeURIComponent(this.TypedSearch);
      fetch(
        "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
          byteArray +
          ".json?access_token=" +
          this.geocoder.options.accessToken
      )
        // fetch  ('https://api.mapbox.com/geocoding/v5/mapbox.places/2%20Lincoln%20Memorial%20Cir%20NW.json?access_token=' + this.geocoder.options.accessToken)
        .then((res) => res.json())
        .then((res) => {
          this.entries = res.features;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    fields() {
      if (!this.selectedAddress) return [];

      return Object.keys(this.selectedAddress).map((key) => {
        return {
          key,
          value: this.selectedAddress[key] || "n/a",
        };
      });
    },
    results() {
      return this.entries.map((entry) => {
        // console.log(entry.place_name.length)
        // place_name
        const PlaceName =
          entry.place_name.length > this.descriptionLimit
            ? entry.place_name.slice(0, this.descriptionLimit) + "..."
            : entry.place_name;
        // console.log(PlaceName)
        // return  PlaceName
        return Object.assign({}, entry, { PlaceName });
      });
    },
  },
  created() {
    // token
    mapboxgl.accessToken =
      "pk.eyJ1IjoibmF5YW5hbiIsImEiOiJja3BtNzlpdTgwZjd4Mm9xcTYwbmtpdzZzIn0.fXsRJJcqT2vatoXlS1N5Qg";

    // geocoder
    var self = this;
    self.geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      types: "country,region,place,postcode,locality,neighborhood",
      mapboxgl: mapboxgl,
    });

    self.geocoder.addTo("#geocoder");
  },
  methods: {},
};
</script>
<style>
</style>

import Vue from 'vue'
import router from 'vue-router'
// import SplashScreen from '@/components/SplashScreen.vue'
import CateView from '@/components/CateView.vue'
import Dashboard from '@/components/Dashboard.vue'
import Categories from '@/components/Categories.vue'
import LoginScreen from '@/components/LoginScreen.vue'
import ManagePages from '@/components/ManagePages.vue'
import Companies from '@/components/Companies.vue'
import EditText from '@/components/EditText.vue'
import Map from '@/components/Map.vue'
Vue.use(router)

export default new router({
  routes: [
    {
      path: '/companies',
      name: 'Companies',
      component: Companies
    },
    {
      path: '/cate_view',
      name: 'CateView',
      component: CateView
    },
    {
      path: '/manage_pages',
      name: 'ManagePages',
      component: ManagePages
    },
    {
      path: '/categories',
      name: 'Categories',
      component: Categories
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard
    },
    {
      path: '/',
      name: 'LoginScreen',
      component: LoginScreen
    },
    {
      path: '/help_text_edit',
      name: 'EditText',
      component: EditText
    },
    {
      path: '/map',
      name: 'Map',
      component: Map
    },
  ]
})

export default function (Vue) {
    Vue.auth = {
      setToken (token) {
        localStorage.setItem('token', token)
      },
      // setUserid (id) {
      //   localStorage.setItem('userId', id)
      // },
  
      getToken () {
        var token = localStorage.getItem('token')
        // var userId = localStorage.getItem('userId')
        if (!token) {
          return null
        } else {
          return token
        }
      },
      // getUserid () {
      //   var userId = localStorage.getItem('userId')
      //   if (!userId) {
      //     return null
      //   } else {
      //     return userId
      //   }
      // },
      test () {
        alert('hellow')
      },
      destroyToken () {
        localStorage.removeItem('token')
        // localStorage.removeItem('role')
      },
     
  
      getDomain () {
        return Vue.http.options.root
      }
    }
    // Vue.http.interceptors.push((request, next) => {
    //   var accessToken = localStorage.getItem('token')
    //   request.headers.set('Authorization', 'Bearer ' + accessToken)
    //   next()
    // })
    Object.defineProperties(Vue.prototype, {
      $auth: {
        get: () => {
          return Vue.auth
        }
      },
      $noti: {
        get: () => {
          return Vue.noti
        }
      }
    })
  }
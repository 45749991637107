<template>
  <v-container fluid>
    <v-row class="text-center" :class="{ 'px-16': $vuetify.breakpoint.lgOnly }">
      <!-- :style="{ height: windowHeight - 100 + 'px' }" -->
      <v-col class="text-left mb-2 mt-4" cols="6" align-self="start">
        <h3 class="text-uppercase font-weight-medium subColorTxt">Companies</h3>
      </v-col>
      <v-col class="text-right mb-2 mt-4" cols="6">
        <h3 class="text-uppercase font-weight-medium subColorTxt">
          Total : {{ totalCount }}
        </h3>
      </v-col>

      <v-col class="text-left mainDarkColorBg pa-8" cols="12">
        <v-row class="ma-0 pa-0">
          <v-col cols="12" md="4">
            <v-text-field
              v-model="SearchName"
              rounded
              dense
              flat
              label="Search / Filter"
              append-icon="mdi-search"
              placeholder="Name"
              solo
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0">
          <v-col
            cols="12"
            md="3"
            v-for="company in companies"
            :key="company.id"
          >
            <v-card class="whiteBg borderR15 py-3 px-4" elevation="0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <h3
                    class="font-weight-medium mb-2 text-uppercase text-truncate"
                  >
                    {{ company.name }}
                  </h3>

                  <p class="font-weight-medium f-18 text-truncate ma-0">
                    {{ company.description }}
                  </p>
                </v-col>
                <v-col class="text-right" cols="12">
                  <v-btn
                    @click="companyConfirmDelete(company)"
                    fab
                    x-small
                    class="red white--text mr-3"
                    elevation="0"
                  >
                    <v-icon>mdi-delete-forever</v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    x-small
                    class="subColorBg"
                    elevation="0"
                    @click="editCompany(company)"
                  >
                    <v-avatar size="18px">
                      <img
                        src="./../assets/icon/Icon material-edit.svg"
                        alt=""
                      />
                    </v-avatar>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <pagination v-bind:paginationFor="paginationFor"></pagination>
      </v-col>
    </v-row>
    <cu-company></cu-company>
    <!-- <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="80%">
        <v-card class="pa-4">
          <v-row class="text-center">
            <v-col>
              <h1>Add new company</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="8">
              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12">
                      <h3 class="">Selected category</h3>
                      <p class="mb-0">
                        <v-btn icon small>
                          <v-icon size="30px" dark>
                            {{ selectedCate.icon }}
                          </v-icon>
                        </v-btn>
                        <span class="ml-1"> {{ selectedCate.name }} </span>
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12">
                      <h3 class="">Selected sub category</h3>
                      <p class="ma-0">
                        <span class="">{{ selectedSubCate.name }} </span>
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12">
                      <h3 class="mb-3">
                        Add
                        <span v-if="companyEditEnable"> new</span> background
                        image
                      </h3>
                      <croppa
                        :dth="Number(300)"
                        :height="Number(100)"
                        canvas-color="#F5A623"
                        placeholder-color="#ffffff"
                        v-model="myCroppa"
                      >
                      </croppa>
                    </v-col>
                    <v-col v-if="companyEditEnable">
                      <h3 class="mb-3">Added background image</h3>
                      <v-avatar width="auto" height="100px" tile>
                        <img :src="placeData.image" alt="" />
                      </v-avatar>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <h3 class="mb-3">Name of place</h3>

                      <v-text-field
                        dense
                        v-model="placeData.name"
                        flat
                        label="Enter name of company"
                        placeholder="abc"
                        solo
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="6">
                  <h3 class="mb-3">Social network accounts</h3>
                  <v-text-field
                    dense
                    flat
                    v-model="webAdd"
                    label="Website address"
                    placeholder="http://www.test.com"
                    append-icon="mdi-web"
                    solo
                  ></v-text-field>
                  <v-text-field
                    dense
                    v-model="instaAdd"
                    flat
                    label="Instagram"
                    placeholder="abc_company"
                    append-icon="mdi-instagram"
                    solo
                  ></v-text-field>
                  <v-text-field
                    dense
                    v-model="fbAdd"
                    append-icon="mdi-facebook"
                    flat
                    label="Facebook"
                    placeholder="abc_company"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="pt-0" cols="12">
                  <h3 class="mb-3">Description</h3>
                  <v-textarea
                    label="Type something..."
                    dense
                    v-model="placeData.description"
                    flat
                    rows="2"
                    height="100px"
                    solo
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="4">
              <h3 class="mb-3 d-inline">Branch addresses</h3>
             
              <v-autocomplete
                label="Add address"
                :search-input.sync="TypedSearch"
                :items="results"
                :loading="isLoading"
                color="orange"
                clearable
                solo
                hide-no-data
                flat
                hide-selected
                item-text="PlaceName"
                item-value="index"
                return-object
                v-model="selectedAddress"
                class="mt-2"
              ></v-autocomplete>
              <v-card
                v-for="(adres, index) in addresses"
                :key="index"
                outlined
                class="grayBorder borderR15 pa-2 my-3"
              >
                <v-row align="center">
                  <v-col cols="10">
                    <p class="ma-0">
                      {{ adres.name }}
                    </p>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      @click="removeAddress(index)"
                      x-small
                      icon
                      class="subColorBg ml-2"
                    >
                      <v-avatar size="12px">
                        <img
                          src="./../assets/icon/Component 3.svg"
                          style="transform: rotate(45deg)"
                          alt=""
                        />
                      </v-avatar>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="subColorTxt" text @click="cancelAddCom()">
              cancel
            </v-btn>
            <v-btn
              v-if="companyEditEnable"
              :loading="loading"
              class="subColorBg whiteTxt"
              @click="updateCompany()"
              text
            >
              update
            </v-btn>
            <v-btn
              v-else
              :loading="loading"
              class="subColorBg whiteTxt"
              @click="addCompany()"
              text
            >
              add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row> -->
    <v-row justify="center">
      <v-dialog v-model="companyDeleteConfirm" max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            Do you want to delete this Company?
          </v-card-title>

          <v-card-text>
            When you click on 'DELETE' button this Company will removed from
            this app!
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="grey darken-1"
              text
              @click="$store.companyDeleteConfirm = false"
            >
              cancel
            </v-btn>

            <v-btn color="red darken-1" text @click="deleteCompany()">
              delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import GlobalFunctions from "./../GlobalFunctions.js";
import CUCompany from "./modals/CreateEditCompany.vue";
import Pagination from "./sub_components/Pagination.vue";

export default {
  mixins: [GlobalFunctions],
  name: "LoginScreen",

  data: () => ({
    paginationFor: "companies",
    SearchName: "",
    isLoading: false,
    selectedAddress: null,
    // map: '',
    TypedSearch: null,
    addSubCateModal: false,
    SubCateName: "",
    editSubCateis: false,
    selectedSubCateId: "",
    deleteConfirm: false,
  }),
  watch: {
    SearchName: function () {
      this.filterCompany(this.SearchName);
    },
  },
  components: {
    "cu-company": CUCompany,
    pagination: Pagination,
  },
  methods: {
    editCompany(company) {
      this.$store.companyEditEnable = true;
      this.$store.placeData = company;
      this.$store.selectedSubCate = company.category[1];
      this.$store.selectedCate = company.category[0];
      this.$store.dialog = true;
      console.log(company);
    },
    companyConfirmDelete(sub) {
      console.log(sub);
      this.$store.companyDeleteConfirm = true;
      this.$store.selectedCompany = sub;
    },
  },
  created() {
    this.getAllCompanies(1);
  },
  computed: {
    totalCount() {
      return this.$store.totalCount;
    },
    companyDeleteConfirm() {
      return this.$store.companyDeleteConfirm;
    },
    selectedCompany() {
      return this.$store.selectedCompany;
    },
    loading() {
      return this.$store.loading;
    },
    companies() {
      return this.$store.companies;
    },
    windowHeight() {
      return this.$store.windowHeight;
    },
    selectedCate() {
      return this.$store.selectedCate;
    },
    selectedSubCate() {
      return this.$store.selectedSubCate;
    },
  },
};
</script>

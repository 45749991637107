<template>
  <v-container fluid>
    <v-row
      class="text-center"
      justify="center"
      :class="{ 'px-16': $vuetify.breakpoint.lgOnly }"
      :style="{ height: windowHeight - 100 + 'px' }"
    >
      <v-col class="text-left" cols="12" align-self="start">
        <h3 class="text-uppercase font-weight-medium subColorTxt">
          text editor
        </h3>
      </v-col>
      <v-col cols="12">
        <v-card class="borderR15 pa-5">
          <!-- Two-way Data-Binding -->
          <quill-editor
            ref="myQuillEditor"
            v-model="content.html"
            :options="editorOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
          />
          <!-- @change="onEditorChange($event)" -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="subColorTxt" text to="/dashboard"> cancel </v-btn>
            <v-btn
              :loading="loading"
              class="subColorBg whiteTxt"
              @click="updateEditedText()"
              text
            >
              update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GlobalFunctions from "./../GlobalFunctions.js";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  mixins: [GlobalFunctions],
  name: "LoginScreen",

  data: () => ({
    content: "<h2>Loading...</h2>",
    response: "",
    editorOption: {
      // Some Quill options...
    },
  }),
  methods: {
    getPageContent(path) {
      this.callLoading(true);
      this.$http
        .get("viewPage/" + path)
        .then((res) => {
          this.response = res;
          this.content = res.body;
          this.callAlert(true, "success", "Contents loaded.");
          this.callLoading(false);
        })
        .catch((err) => {
          console.log(err.body);
          this.callLoading(false);
        });
    },
    updateEditedText() {
      this.callLoading(true);
      var data = this.content;

      // if (this.pageEdit == "help") {
      //   data = {
      //     id: 1,
      //     title: "Help page",
      //     path: "help_text_edit",
      //     html: this.content,
      //   };
      // } else {
      //   data = {
      //     id: 1,
      //     title: "Help page",
      //     path: "help_text_edit",
      //     html: this.content,
      //   };
      // }
      console.log(data);
      this.$http
        .post("updatePage", data)
        .then((res) => {
          console.log(res);
          // this.content = res.bodyText;
          this.$router.push("/dashboard");
          this.callAlert(true, "success", "Contents updated.");
          this.callLoading(false);
        })
        .catch((err) => {
          console.log(err.body);
          this.callLoading(false);
        });
    },
    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
  },
  mounted() {
    console.log("this is current quill instance object", this.editor);
  },
  computed: {
    pageEdit() {
      return this.$store.pageEdit;
    },
    loading() {
      return this.$store.loading;
    },
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
    windowHeight() {
      return this.$store.windowHeight;
    },
    alertModal() {
      return this.$store.alertModal;
    },
  },
  components: {
    quillEditor,
  },
  created() {
    // alert(this.pageEdit);
    if (this.pageEdit == "help") {
      this.getPageContent("help_text_edit");
    }
    if (this.pageEdit == "welcome") {
      this.getPageContent("welcome");
    }
  },
};
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="80%">
      <v-card class="pa-4">
        <v-row>
          <v-col class="text-center">
            <h1>Add new company</h1>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12">
                    <h3 class="d-inline">*Selected category</h3>
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-width="200"
                      offset-x
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          fab
                          x-small
                          class="subColorBg ml-2"
                          elevation="0"
                        >
                          <v-avatar size="18px">
                            <img src="./../../assets/icon/Component 3.svg" alt="" />
                          </v-avatar>
                        </v-btn>
                      </template>
                      <v-card :loading="cardLoading">
                        <v-card-title>
                          <v-row align="center" class="pa-0">
                            <v-col cols="9" class="">
                              <h5>Select categories</h5>
                            </v-col>
                            <v-col cols="3" class="text-right">
                              <v-btn x-small icon top right @click="menu = false">
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-list-group
                          no-action
                          class="text-left"
                          v-for="cate in categoryList"
                          :key="cate.id"
                        >
                          <template v-slot:activator>
                            <v-list-item-icon class="mr-3">
                              <v-icon>{{ cate.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ cate.name }}</v-list-item-title>
                          </template>
                          <v-list-item v-for="sub in cate.children" :key="sub.id" link>
                            <v-list-item-title
                              @click="addmoreCate(cate, sub)"
                              v-text="sub.name"
                              :class="{
                                'font-weight-bold': sub.type === 'webshop',
                              }"
                            ></v-list-item-title>
                          </v-list-item>
                        </v-list-group>
                      </v-card>
                    </v-menu>

                    <p
                      class="mb-2"
                      v-for="(moreCate, idx) in moreSelectedcate"
                      v-bind:key="idx"
                    >
                      <v-btn icon small>
                        <v-icon size="30px" dark>
                          {{ moreCate.icon }}
                        </v-icon>
                      </v-btn>
                      <span class="ml-1">
                        {{ moreCate.name }}
                      </span>
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12">
                    <h3 class="">*Selected sub category</h3>
                    <p class="ma-0" v-for="(sub, id) in moreSelectedSubcate" :key="id">
                      <span class=""
                        >{{ sub.name }}
                        <v-btn x-small icon @click="RemoveCate(sub, id)">
                          <v-icon>mdi-close</v-icon>
                        </v-btn></span
                      >
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12">
                    <h3 class="mb-3">
                      *Add
                      <span v-if="companyEditEnable"> new</span> background image
                    </h3>
                    <croppa
                      :dth="Number(300)"
                      :height="Number(100)"
                      canvas-color="#ffffff"
                      placeholder-color="#000000"
                      v-model="myCroppa"
                    >
                    </croppa>
                  </v-col>
                  <v-col v-if="companyEditEnable">
                    <h3 class="mb-3">Added background image</h3>
                    <v-avatar width="auto" height="100px" tile>
                      <img :src="placeData.image" alt="" />
                    </v-avatar>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h3 class="mb-3">*Name of company</h3>

                    <v-text-field
                      dense
                      v-model="placeData.name"
                      flat
                      label="Enter name of company"
                      placeholder="abc"
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="6">
                <h3 class="mb-3">
                  <span v-if="webshopisin">*</span>Social network accounts
                </h3>
                <v-text-field
                  dense
                  flat
                  v-model="webAdd"
                  label="Website address"
                  placeholder="http://www.test.com"
                  append-icon="mdi-web"
                  solo
                ></v-text-field>
                <v-text-field
                  dense
                  v-model="instaAdd"
                  v-if="webshopisin == false"
                  flat
                  label="Instagram"
                  placeholder="abc_company"
                  append-icon="mdi-instagram"
                  solo
                ></v-text-field>
                <v-text-field
                  dense
                  v-if="webshopisin == false"
                  v-model="fbAdd"
                  append-icon="mdi-facebook"
                  flat
                  label="Facebook"
                  placeholder="abc_company"
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0" v-if="webshopisin == false">
              <v-col class="pt-0" cols="12">
                <h3 class="mb-3">Description</h3>
                <v-textarea
                  label="Type something..."
                  dense
                  v-model="placeData.description"
                  flat
                  rows="2"
                  height="100px"
                  solo
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="4" v-if="webshopisin == false">
            <h3 class="mb-3 d-inline">Branch addresses</h3>
            <v-autocomplete
              label="Add address"
              :search-input.sync="TypedSearch"
              :items="results"
              :loading="isLoading"
              color="orange"
              clearable
              solo
              hide-no-data
              flat
              hide-selected
              item-text="PlaceName"
              item-value="index"
              return-object
              v-model="selectedAddress"
              class="mt-2"
            ></v-autocomplete>
            <v-card
              v-for="(adres, index) in addresses"
              :key="index"
              outlined
              class="grayBorder borderR15 pa-2 my-3"
            >
              <v-row align="center">
                <v-col cols="10">
                  <p class="ma-0">
                    {{ adres.name }}
                  </p>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    @click="removeAddress(index)"
                    x-small
                    icon
                    class="subColorBg ml-2"
                  >
                    <v-avatar size="12px">
                      <img
                        src="./../../assets/icon/Component 3.svg"
                        style="transform: rotate(45deg)"
                        alt=""
                      />
                    </v-avatar>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="subColorTxt" text @click="cancelAddCom()"> cancel </v-btn>
          <v-btn
            v-if="companyEditEnable"
            :loading="loading"
            class="subColorBg whiteTxt"
            @click="updateCompany()"
            text
          >
            update
          </v-btn>
          <v-btn
            v-else
            :loading="loading"
            class="subColorBg whiteTxt"
            @click="addCompany()"
            text
          >
            add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from "mapbox-gl";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import GlobalFunctions from "./../../GlobalFunctions.js";
export default {
  mixins: [GlobalFunctions],
  name: "App",

  components: {},

  data: () => ({
    webshopisin: false,
    cardLoading: false,
    selectedAddress: null,
    menu: false,
    myCroppa: null,
    fbAdd: "",
    instaAdd: "",
    webAdd: "",
    TypedSearch: null,
    isLoading: false,
    addresses: [],
    geocoder: "",
    descriptionLimit: 60,
    entries: [],
  }),
  watch: {
    dialog: function () {
      this.$store.moreSelectedcate = [];
      this.$store.moreSelectedSubcate = [];
      if (this.dialog) {
        if (this.companyEditEnable) {
          // console.log(this.placeData.category.length);
          for (let index = 0; index < this.placeData.category.length; index++) {
            // console.log(this.placeData.category[index]);
            if (this.placeData.category[index].parent_id == null) {
              this.$store.moreSelectedcate.push(this.placeData.category[index]);
            } else {
              console.log(this.moreSelectedSubcate);
              this.$store.moreSelectedSubcate.push(this.placeData.category[index]);
            }
          }
          this.addresses = this.placeData.address;
          this.webAdd = this.placeData.url[0] ? this.placeData.url[0].url : "";
          this.instaAdd = this.placeData.url[1] ? this.placeData.url[1].url : "";
          this.fbAdd = this.placeData.url[2] ? this.placeData.url[2].url : "";
        } else {
          this.addmoreCate(this.selectedCate, this.selectedSubCate);
        }
        this.checkWebshop();
      }
    },

    // companyEditEnable: function () {

    // },
    selectedAddress: function () {
      var gotAdd = {
        name: this.selectedAddress.place_name.toString(),
        address: JSON.stringify(this.selectedAddress.geometry),
      };
      this.addresses.push(gotAdd);
      // console.log(this.addresses);
      this.selectedAddress = null;
    },
    TypedSearch: function () {
      this.isLoading = true;
      const byteArray = encodeURIComponent(this.TypedSearch);
      mapboxgl.accessToken =
        "pk.eyJ1IjoibmF5YW5hbiIsImEiOiJja3BtNzlpdTgwZjd4Mm9xcTYwbmtpdzZzIn0.fXsRJJcqT2vatoXlS1N5Qg";
      var self = this;
      self.geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        types: "country,region,postcode,place,locality,neighborhood,address,poi",
        mapboxgl: mapboxgl,
      });
      // self.geocoder.addTo("#geocoder");
      fetch(
        "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
          byteArray +
          ".json?access_token=" +
          mapboxgl.accessToken
      )
        // fetch  ('https://api.mapbox.com/geocoding/v5/mapbox.places/2%20Lincoln%20Memorial%20Cir%20NW.json?access_token=' + this.geocoder.options.accessToken)
        .then((res) => res.json())
        .then((res) => {
          this.entries = res.features;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  created() {
    this.$store.placeData.category = [...new Set(this.placeData.category)];
  },
  methods: {
    checkWebshop() {
      for (var j = 0; j < this.$store.moreSelectedSubcate.length; j++) {
        if (this.$store.moreSelectedSubcate[j].type == "webshop") {
          this.webshopisin = true;
          // for (var a = 0; a < this.$store.moreSelectedSubcate.length - 1; a++) {
          //   if (this.$store.moreSelectedSubcate[a].type !== "webshop") {
          //     this.$store.moreSelectedSubcate.splice(a, 1);
          //     this.callAlert(
          //       true,
          //       "error",
          //       "Please add same category types only!"
          //     );
          //   }
          // }
          return;
        } else {
          this.webshopisin = false;
        }
      }
    },
    RemoveCate(cate, index) {
      var cates = this.$store.moreSelectedSubcate;
      var maincates = this.$store.moreSelectedcate;
      var pid = cate.parent_id;
      var check = false;

      for (var j = 0; j < cates.length; j++) {
        if (cates[j].id == cate.id) {
          cates.splice(index, 1);
          this.checkWebshop();
        }
      }
      for (let i = 0; i < cates.length; i++) {
        if (pid == cates[i].parent_id) {
          return (check = true);
        }
      }
      if (check == false) {
        for (var a = 0; a < maincates.length; a++) {
          if (maincates[a].id == pid) {
            maincates.splice(maincates[a], 1);
            this.callAlert(true, "success", "Category removed!");
          }
        }
      }

      this.$store.moreSelectedcate = maincates;
      this.$store.moreSelectedSubcate = cates;

      //   this.$delete(this.addresses, index);
    },
    addcateFinal() {
      var cates = [];
      for (var i = 0; i < this.$store.moreSelectedcate.length; i++) {
        // this.placeData.category.push(this.$store.moreSelectedcate[i].id);

        cates.push(this.$store.moreSelectedcate[i].id);
      }
      for (var j = 0; j < this.$store.moreSelectedSubcate.length; j++) {
        // this.placeData.category.push(this.$store.moreSelectedSubcate[i].id);
        cates.push(this.$store.moreSelectedSubcate[j].id);
      }
      this.$store.placeData.category = cates;
      //   [...new Set(this.$store.moreSelectedcate)];
    },

    generateImage() {
      let url = this.myCroppa.generateDataUrl();
      if (!url) {
        console.log("no image");
        return;
      }
      // console.log(url);
      this.placeData.image = url;
    },
    addCompany() {
      this.generateImage();
      var urls = [];
      if (this.webAdd != "") {
        urls.push({ name: "web", url: this.webAdd });
      }
      if (this.instaAdd != "") {
        urls.push({ name: "insta", url: this.instaAdd });
      }
      if (this.fbAdd != "") {
        urls.push({ name: "fb", url: this.fbAdd });
      }

      this.placeData.url = urls;
      this.placeData.address = this.addresses;
      this.addcateFinal();
      console.log(this.placeData);
      this.callLoading(true);
      if (
        this.$store.placeData.name !== "" &&
        this.$store.placeData.category.length != 0
      ) {
        this.$http
          .post("createPlace", this.placeData)
          .then((res) => {
            this.dialog = false;
            console.log(res.body);
            this.callLoading(false);
            this.callAlert(true, "success", "New company added.");
            this.getCompanies(this.selectedSubCate.id, "", 1);
            this.cancelAddCom();
          })
          .catch((err) => {
            this.callLoading(false);
            this.callAlert(true, "error", err.body.error);
            console.log(err.body);
          });
      } else {
        this.callLoading(false);
        this.callAlert(true, "error", "Please add required details!");
      }
    },
    updateCompany() {
      this.callLoading(true);
      this.generateImage();
      var urls = [];
      if (this.webAdd != "") {
        urls.push({ name: "web", url: this.webAdd });
      }
      if (this.instaAdd != "") {
        urls.push({ name: "insta", url: this.instaAdd });
      }
      if (this.fbAdd != "") {
        urls.push({ name: "fb", url: this.fbAdd });
      }
      if (this.placeData.image.substring(0, 5) === "https") {
        delete this.placeData["image"];
      }
      this.placeData.url = urls;
      this.placeData.address = this.addresses;
      this.addcateFinal();
      console.log(this.placeData);
      this.callLoading(true);
      if (
        this.$store.placeData.name !== "" &&
        this.$store.placeData.category.length != 0
      ) {
        this.$http
          .post("updatePlace", this.placeData)
          .then((res) => {
            this.$store.dialog = false;
            console.log(res.body);
            this.callLoading(false);
            this.callAlert(true, "success", "New company added.");
            this.getCompanies(this.selectedSubCate.id, "", 1);
            this.$srore.placeData = "";
          })
          .catch((err) => {
            this.callLoading(false);
            this.callAlert(true, "error", err.body.error);
            console.log(err.body);
          });
      } else {
        this.callLoading(false);
        this.callAlert(true, "error", "Please add required details!");
      }
    },
    cancelAddCom() {
      this.$store.moreSelectedSubcate = [];
      this.$store.moreSelectedcate = [];
      this.$store.placeData.name = "";
      this.$store.placeData.image = null;
      this.$store.placeData.description = "";
      this.$store.placeData.category = [];
      this.$store.placeData.url = "";
      this.$store.placeData.status = "publish";
      this.$store.placeData.address = [
        {
          name: "",
          address: "",
        },
      ];
      this.$store.companyEditEnable = false;
      this.webAdd = "";
      this.instaAdd = "";
      this.fbAdd = "";
      this.addresses = [];
      this.$store.dialog = false;
      this.callLoading(false);
      this.getCompanies(this.selectedCate.id, "", 1);
    },
    removeAddress(index) {
      this.$delete(this.addresses, index);
    },
    addmoreCate(cate, sub) {
      this.cardLoading = true;
      var exists = this.$store.moreSelectedcate.some(function (field) {
        return field.id === cate.id;
      });
      if (!exists) {
        this.$store.moreSelectedcate.push(cate);
      }
      var Aexists = this.$store.moreSelectedSubcate.some(function (fieldz) {
        return fieldz.id === sub.id;
      });
      if (!Aexists) {
        console.log(sub);
        if (this.$store.moreSelectedSubcate.length == 0) {
          if (this.$store.moreSelectedSubcate.push(sub)) {
            // this.callAlert(true, "success", "Category selected!");
            this.cardLoading = false;
            this.menu = false;
          }
        } else {
          if (this.$store.moreSelectedSubcate[0].type == sub.type) {
            if (this.$store.moreSelectedSubcate.push(sub)) {
              // this.callAlert(true, "success", "Category selected!");
              this.cardLoading = false;
              this.menu = false;
            }
          } else {
            this.callAlert(true, "error", "Please add same type categories only!");
          }
        }
      } else {
        this.callAlert(true, "error", "Already selected!");
        this.cardLoading = false;
      }
      this.checkWebshop();
    },
  },
  computed: {
    fields() {
      if (!this.selectedAddress) return [];

      return Object.keys(this.selectedAddress).map((key) => {
        return {
          key,
          value: this.selectedAddress[key] || "n/a",
        };
      });
    },
    results() {
      return this.entries.map((entry) => {
        // console.log(entry.place_name.length)
        // place_name
        const PlaceName =
          entry.place_name.length > this.descriptionLimit
            ? entry.place_name.slice(0, this.descriptionLimit) + "..."
            : entry.place_name;
        // console.log(PlaceName)
        // return  PlaceName
        return Object.assign({}, entry, { PlaceName });
      });
    },
    placeData() {
      return this.$store.placeData;
    },
    moreSelectedSubcate() {
      return this.$store.moreSelectedSubcate;
    },

    selectedCate() {
      return this.$store.selectedCate;
    },
    selectedSubCate() {
      return this.$store.selectedSubCate;
    },
    companyEditEnable() {
      return this.$store.companyEditEnable;
    },
    moreSelectedcate() {
      return this.$store.moreSelectedcate;
    },
    categoryList() {
      return this.$store.categoryList;
    },
    alertModal() {
      return this.$store.alertModal;
    },
    dialog() {
      return this.$store.dialog;
    },
    loading() {
      return this.$store.loading;
    },
  },
};
</script>
<style scoped>
.geocoder {
  position: relative;
  z-index: 1;
  /* width: 50%;
  left: 50%;
  margin-left: -15%; */
}
.mapboxgl-ctrl-geocoder {
  min-width: 100%;
}
</style>

<template>
  <v-container fluid>
    <v-row
      class="text-center"
      :class="{ 'px-16': $vuetify.breakpoint.lgOnly }"
      align="center"
    >
      <!-- :style="{ height: windowHeight - 100 + 'px' }" -->
      <v-col class="text-left mb-2 mt-4" cols="12" align-self="start">
        <h3 class="text-uppercase font-weight-medium subColorTxt">
          Pages & Menus
        </h3>
      </v-col>

      <v-col cols="12" md="3" xs="12">
        <v-card
          justify="center"
          class="borderR15 mainDarkColorBg pa-5"
          elevation="0"
        >
          <v-row>
            <v-col cols="12">
              <v-btn
                @click="openPageCreateMenu()"
                fab
                class="subColorBg pa-6"
                elevation="0"
              >
                <v-avatar size="30px">
                  <img src="./../assets/icon/Component 3.svg" alt="" />
                </v-avatar>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        class="text-left"
        cols="12"
        md="3"
        lg="3"
        xs="12"
        v-for="page in pages"
        :key="page.id"
      >
        <v-card
          class="whiteTxt hoverClass borderR15 mainDarkColorBg pa-5"
          @click="mainMenu = page"
          elevation="0"
        >
          <v-row>
            <v-col cols="12">
              <h3 class="font-weight-medium">
                {{ page.title }}
              </h3>
            </v-col>
            <v-col class="text-right" cols="12">
              <v-btn
                :disabled="page.path == 'welcome'"
                @click="confirmPageDelete(page.id)"
                fab
                x-small
                class="red white--text mr-3"
                elevation="0"
              >
                <v-icon>mdi-delete-forever</v-icon>
              </v-btn>
              <v-btn
                :disabled="page.path == 'welcome'"
                @click="editPage(page)"
                fab
                x-small
                class="subColorBg mr-3"
                elevation="0"
              >
                <v-avatar size="18px">
                  <img src="./../assets/icon/Icon material-edit.svg" alt="" />
                </v-avatar>
              </v-btn>

              <!-- <v-btn :disabled="page.path == 'welcome'" @click="openCategory(page)" fab x-small class="subColorBg"
                elevation="0">
                <v-avatar size="18px">
                  <img src="./../assets/icon/Icon awesome-angle-right.svg" alt="" />
                </v-avatar>
              </v-btn> -->
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- <v-col cols="12">
        <pagination v-bind:paginationFor="paginationFor"></pagination>
      </v-col> -->
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="deleteConfirmPage" max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            Do you want to delete this Page?
          </v-card-title>

          <v-card-text>
            Only this page will removed from this app!
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="grey darken-1"
              text
              @click="deleteConfirmPage = false"
            >
              cancel
            </v-btn>

            <v-btn color="red darken-1" text @click="deletePage()">
              delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="addMenuModal" persistent max-width="400px">
        <v-card class="pa-4">
          <v-row class="text-center">
            <v-col>
              <h1 v-if="e1 == 2">
                <span v-if="menuItemEdit"> Edit </span>
                <span v-else>Add new</span>
                main menu
              </h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3 class="mb-3">Title of menu</h3>
              <v-text-field
                dense
                v-model="menu.name"
                flat
                label="Name of menu"
                placeholder="abc"
                solo
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <h3>Select an icon for menu</h3>
              <p class="mb-3 f10 grayTxt">
                (If icon not in this list, please copy an icon name from
                <a
                  target="_blank"
                  href="https://pictogrammers.github.io/@mdi/font/5.4.55/"
                  >Mdi-Icons</a
                >
                web site)
              </p>

              <v-combobox
                v-model="mainMenuIcon"
                :items="cateIconList"
                label="Select an icon"
                item-text="icon"
                item-value="item"
                solo
              >
                <template slot="item" slot-scope="data">
                  <v-icon class="mr-3">{{ data.item.icon }}</v-icon>
                  <span class="cb-item">{{ data.item.icon }}</span>
                </template>
              </v-combobox>
              <!-- <v-select  v-model="icon" flat item-text="name" :items="categoryList" label="Icon name" solo></v-select> -->

              <h4>Paste copied icon from web site</h4>
              <p class="mb-3 f10 grayTxt">
                (Leave blank if selected an icon from list above)
              </p>

              <v-text-field
                dense
                v-model="copiedIcon"
                flat
                label="Paste icon name here"
                placeholder="mdi-icon-name"
                solo
              ></v-text-field>
              <!-- <v-select  v-model="icon" flat item-text="name" :items="categoryList" label="Icon name" solo></v-select> -->
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="subColorTxt" text @click="cancelMenu()">
              cancel
            </v-btn>
            <v-btn
              v-if="menuItemEdit"
              class="subColorBg whiteTxt"
              text
              @click="addMenu()"
            >
              update
            </v-btn>
            <v-btn v-else class="subColorBg whiteTxt" text @click="addMenu()">
              add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-dialog v-model="addSubMenuModal" persistent max-width="400px">
        <v-card class="pa-4">
          <v-row class="text-center">
            <v-col>
              <!-- <h1 v-if="e1== 2">
                <span v-if="menuItemEdit"> Edit </span>
                <span v-else>Add new</span>
                main menu
              </h1> -->
              <h1 v-if="e1 == 3">
                <span>Add sub menu for {{ mainMenu.name }} </span>
              </h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3 class="mb-3">Title of sub menu</h3>
              <v-text-field
                dense
                v-model="subMenuName"
                flat
                label="Name of menu"
                placeholder="abc"
                solo
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <h3>Select an icon for sub menu</h3>
              <p class="mb-3 f10 grayTxt">
                (If icon not in this list, please copy an icon name from
                <a
                  target="_blank"
                  href="https://pictogrammers.github.io/@mdi/font/5.4.55/"
                  >Mdi-Icons</a
                >
                web site)
              </p>

              <v-combobox
                v-model="subMenuIcon"
                :items="cateIconList"
                label="Select an icon"
                item-text="icon"
                item-value="item"
                solo
              >
                <template slot="item" slot-scope="data">
                  <v-icon class="mr-3">{{ data.item.icon }}</v-icon>
                  <span class="cb-item">{{ data.item.icon }}</span>
                </template>
              </v-combobox>
              <!-- <v-select  v-model="icon" flat item-text="name" :items="categoryList" label="Icon name" solo></v-select> -->

              <h4>Paste copied icon from web site</h4>
              <p class="mb-3 f10 grayTxt">
                (Leave blank if selected an icon from list above)
              </p>

              <v-text-field
                dense
                v-model="copiedIcon"
                flat
                label="Paste icon name here"
                placeholder="mdi-icon-name"
                solo
              ></v-text-field>
              <!-- <v-select  v-model="icon" flat item-text="name" :items="categoryList" label="Icon name" solo></v-select> -->
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="subColorTxt" text @click="cancelSubMenu()">
              cancel
            </v-btn>
            <v-btn
              v-if="menuItemEdit"
              class="subColorBg whiteTxt"
              text
              @click="updateSubMenu()"
            >
              update
            </v-btn>
            <v-btn
              v-else
              class="subColorBg whiteTxt"
              text
              @click="addSubMenu()"
            >
              add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- main add page model stepper -->
    <v-dialog v-model="addPageModel" fullscreen>
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">
            Create page with content
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2">
            Select main menu for created page
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3"> Add sub menu to page</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card
              elevation="0"
              class="mb-12"
              :min-height="windowHeight - 200"
            >
              <!-- :height="windowHeight - 200" -->
              <v-row>
                <v-col cols="12" lg="4">
                  <h3 class="mb-3">Title of page</h3>
                  <v-text-field
                    dense
                    v-model="page.title"
                    flat
                    label="Name of category"
                    placeholder="abc"
                    solo
                  >
                  </v-text-field>
                </v-col>
                <!-- <v-col cols="12" lg="4">
                  <h3 class="mb-3">Page path</h3>
                  <v-text-field
                    dense
                    v-model="page.path"
                    flat
                    label="Name of category"
                    placeholder="abc"
                    solo
                  ></v-text-field>
                </v-col> -->
              </v-row>
              <v-row
                class="text-center"
                justify="center"
                :class="{ 'px-16': $vuetify.breakpoint.lgOnly }"
              >
                <v-col class="text-left" cols="12" align-self="start">
                  <h3 class="mb-3">Text editor</h3>
                </v-col>
                <v-col cols="12">
                  <v-card class="borderR15 pa-5">
                    <!-- Two-way Data-Binding -->
                    <quill-editor
                      ref="myQuillEditor"
                      v-model="page.html"
                      :options="editorOption"
                      @blur="onEditorBlur($event)"
                      @focus="onEditorFocus($event)"
                      @ready="onEditorReady($event)"
                    />
                    <!-- @change="onEditorChange($event)" -->
                    <!-- <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn class="subColorTxt" text to="/dashboard">
                        cancel
                      </v-btn>
                      <v-btn
                        :loading="loading"
                        class="subColorBg whiteTxt"
                        @click="updateEditedText()"
                        text
                      >
                        update
                      </v-btn>
                    </v-card-actions> -->
                  </v-card>
                </v-col>
              </v-row>
            </v-card>

            <v-btn class="subColorBg whiteTxt" @click="savePage()">
              Continue
            </v-btn>

            <v-btn
              text
              @click="
                {
                  addPageModel = false;
                }
              "
            >
              Cancel
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card
              class="mb-12"
              elevation="0"
              :min-height="windowHeight - 200"
            >
              <v-row class="mt-12 ml-3">
                <v-col>
                  <h3>Add / Edit list title for created page</h3>

                  <p>
                    Added {{ e1 == 2 ? "main" : e1 == 3 ? "sub" : "" }}
                    menus for created pages
                  </p>
                  <h4>Page : {{ page.title }}</h4>
                  <h4>Selected menu : {{ mainMenu.name }}</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" lg="3" xs="12">
                  <v-card
                    align="center"
                    justify="center"
                    class="borderR15 mainDarkColorBg pa-5"
                    elevation="0"
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-btn
                          @click="openAddMenuModel()"
                          fab
                          class="subColorBg pa-6"
                          elevation="0"
                        >
                          <v-avatar size="30px">
                            <img
                              src="./../assets/icon/Component 3.svg"
                              alt=""
                            />
                          </v-avatar>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col
                  class="text-left"
                  cols="12"
                  md="3"
                  lg="3"
                  xs="12"
                  v-for="menu in menus"
                  :key="menu.id"
                >
                  <v-card
                    class="whiteTxt hoverClass borderR15 mainDarkColorBg pa-5"
                    elevation="0"
                  >
                    <v-row>
                      <v-col cols="12">
                        <h3 class="font-weight-medium">
                          <v-btn icon outlined class="subColorTxt">
                            <v-icon medium>{{ menu.icon }}</v-icon>
                          </v-btn>

                          {{ menu.name }}
                        </h3>
                      </v-col>
                      <v-col class="text-right" cols="12">
                        <v-btn
                          @click="confirmDelete(menu.id)"
                          fab
                          x-small
                          class="red white--text mr-3"
                          elevation="0"
                        >
                          <v-icon>mdi-delete-forever</v-icon>
                        </v-btn>
                        <v-btn
                          @click="editMenu(menu)"
                          fab
                          x-small
                          class="subColorBg mr-3"
                          elevation="0"
                        >
                          <v-avatar size="18px">
                            <img
                              src="./../assets/icon/Icon material-edit.svg"
                              alt=""
                            />
                          </v-avatar>
                        </v-btn>

                        <v-btn
                          @click="selectMenuForPage(menu)"
                          fab
                          x-small
                          class="subColorBg"
                          elevation="0"
                        >
                          <v-avatar size="18px">
                            <img
                              src="./../assets/icon/Icon awesome-angle-right.svg"
                              alt=""
                            />
                          </v-avatar>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
            <v-btn text @click="e1 = 1"> Back </v-btn>
            <v-btn class="subColorBg whiteTxt" @click="toAddSubMenu()">
              Continue
            </v-btn>

            <v-btn
              text
              @click="
                {
                  addPageModel = false;
                }
              "
            >
              Cancel
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-card
              class="mb-12"
              elevation="0"
              :min-height="windowHeight - 200"
            >
              <v-col class="text-left mb-2 mt-4" cols="12" align-self="start">
                <p>
                  Added {{ e1 == 2 ? "main" : e1 == 3 ? "sub" : "" }}
                  menus for created pages
                </p>
                <h4>Page : {{ page.title }}</h4>
                <h4>Selected menu : {{ mainMenu.name }}</h4>
                <h4>Selected sub menu : {{ selectedSubMenu.name }}</h4>
                <!-- <h4>
                  menu list :
                  <pre>{{ mainMenu }}</pre>
                </h4> -->
                <!-- <p class="white--text">
          {{ selectedSubCate.name }}
        </p> -->
              </v-col>

              <v-col cols="12">
                <v-row>
                  <v-col cols="12" md="3" lg="3" xs="12">
                    <v-card
                      align="center"
                      justify="center"
                      class="borderR15 mainDarkColorBg pa-5"
                      elevation="0"
                    >
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            @click="addSubMenuModal = true"
                            fab
                            class="subColorBg pa-6"
                            elevation="0"
                          >
                            <v-avatar size="30px">
                              <img
                                src="./../assets/icon/Component 3.svg"
                                alt=""
                              />
                            </v-avatar>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <!-- <v-col cols="3"  class="mb-3">
                    <v-card justify="center" class="borderR15 mainDarkColorBg" elevation="0">
                      <v-row>
                        <v-col cols="12" justify="center">
                          <v-btn @click="addSubMenuModal = true" fab x-small class="subColorBg" elevation="0">
                            <v-avatar size="18px">
                              <img src="./../assets/icon/Component 3.svg" alt="" />
                            </v-avatar>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col> -->
                  <v-col
                    class="text-left"
                    cols="12"
                    md="3"
                    lg="3"
                    xs="12"
                    v-for="sub in mainMenu.children"
                    :key="sub.id"
                  >
                    <v-card
                      class="whiteTxt hoverClass borderR15 mainDarkColorBg pa-5"
                      elevation="0"
                    >
                      <v-row>
                        <v-col cols="12">
                          <h3 class="font-weight-medium">
                            <v-btn icon outlined class="subColorTxt">
                              <v-icon medium>{{ sub.icon }}</v-icon>
                            </v-btn>

                            {{ sub.name }}
                          </h3>
                        </v-col>
                        <v-col class="text-right" cols="12">
                          <v-btn
                            @click="confirmDelete(sub.id)"
                            fab
                            x-small
                            class="red white--text mr-3"
                            elevation="0"
                          >
                            <v-icon>mdi-delete-forever</v-icon>
                          </v-btn>
                          <v-btn
                            @click="editMenu(sub)"
                            fab
                            x-small
                            class="subColorBg mr-3"
                            elevation="0"
                          >
                            <v-avatar size="18px">
                              <img
                                src="./../assets/icon/Icon material-edit.svg"
                                alt=""
                              />
                            </v-avatar>
                          </v-btn>

                          <v-btn
                            @click="selectSubForPage(sub)"
                            fab
                            x-small
                            class="subColorBg"
                            elevation="0"
                          >
                            <v-avatar size="18px">
                              <img
                                src="./../assets/icon/Icon awesome-angle-right.svg"
                                alt=""
                              />
                            </v-avatar>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <!-- <v-col cols="3" class="mb-3" v-for="sub in mainMenu.children" :key="sub.id">
                    <v-card class="borderR15 hoverClass whiteTxt mainDarkColorBg px-4" elevation="0">
                      <v-row align="center">
                        <v-col cols="8" class="text-left">
                          <h4 class="font-weight-medium">
                            {{ sub.name }}
                          </h4>
                        </v-col>
                        <v-col cols="4" class="right">
                          <v-row>
                            <v-btn @click="confirmDelete(sub)" fab x-small class="red white--text mr-3" elevation="0">
                              <v-icon>mdi-delete-forever</v-icon>
                            </v-btn>

                            <v-btn @click="editSubCate(sub)" fab x-small class="subColorBg" elevation="0">
                              <v-avatar size="18px">
                                <img src="./../assets/icon/Icon material-edit.svg" alt="" />
                              </v-avatar>
                            </v-btn>

                            <v-btn @click="selectSubForPage(sub)" fab x-small class="subColorBg" elevation="0">
                              <v-avatar size="18px">
                                <img src="./../assets/icon/Icon awesome-angle-right.svg" alt="" />
                              </v-avatar>
                            </v-btn>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col> -->
                </v-row>
              </v-col>

              <v-row justify="center">
                <v-dialog v-model="deleteConfirm" max-width="290">
                  <v-card>
                    <v-card-title class="text-h5">
                      Do you want to delete this Main menu?
                    </v-card-title>

                    <!-- <v-card-text>
                      When you click on 'DELETE' button this sub category and
                      also created companies below this will permenantly removed
                      from this app!
                    </v-card-text> -->

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        color="grey darken-1"
                        text
                        @click="deleteConfirm = false"
                      >
                        cancel
                      </v-btn>

                      <v-btn color="red darken-1" text @click="deleteMenu()">
                        delete
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-card>

            <v-btn text @click="e1 = 2"> Back </v-btn>
            <v-btn class="subColorBg whiteTxt" @click="updateNewSubMenu()">
              Save
            </v-btn>

            <v-btn
              text
              @click="
                {
                  addPageModel = false;
                }
              "
            >
              Cancel
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>
  </v-container>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

import GlobalFunctions from "./../GlobalFunctions.js";

// import Pagination from "./sub_components/Pagination.vue";
export default {
  mixins: [GlobalFunctions],
  name: "LoginScreen",

  data: () => ({
    subMenuName: "",
    subMenuIcon: "",
    mainMenuIcon: "",
    copiedIcon: "",
    page: {
      title: "",
      path: "",
      html: "<h2>Add page content..</h2>",
    },
    menu: {
      name: "",
      icon: "",
      color: "",
      page: "",
      parent_id: null,
      status: "publish",
    },

    subMenu: {
      name: "",
      icon: "",
      color: "",
      page: "",
      parent_id: null,
      status: "publish",
    },

    paginationFor: "cates",
    colour: "#000000",
    colourVal: "",
    menuItemEdit: false,
    select: "",
    CateName: "",
    cateIcon: "",
    e1: 1,
    mainMenu: "",

    selectedSubMenu: "",
    editSubMenuis: false,
    addSubMenuModal: false,
    addMenuModal: false,
    addCateModal: false,
    addPageModel: false,
    deleteConfirm: false,
    deleteConfirmPage: false,
    selectedMenuid: "",
    content: "<h2>Loading...</h2>",
    response: "",
    editorOption: {
      // Some Quill options...
    },
    titles: [
      "Food and Beverage",
      "Design & Lifestyle",
      "Accommodation",
      "Spa & BeautY",
      "Transport",
      "Services",
      "Experiances",
    ],
  }),
  watch: {
    "page.title"(newVal) {
      //to work with changes in "myArray"
      console.log(this.page);
      console.log(newVal.trim(" ").toLowerCase());
      this.page.path = newVal.replace(/ /g, "").toLowerCase();
    },

    colour: function () {
      this.colourVal = this.colour;
    },
  },
  methods: {
    openAddMenuModel() {
      this.addMenuModal = true;
      this.mainMenuIcon = "";
      this.menu.name = "";
      // this.menu.icon = "";
      // this.menu.color = "";
      // this.menu.parent_id = "";
    },
    updateNewSubMenu() {
      if (this.selectedSubMenu == "") {
        this.callAlert(true, "success", "Without sub menu page updated! ");
      } else {
        console.log(this.selectedSubMenu);
        var data = this.selectedSubMenu;

        this.updateMenu(data);
      }

      this.getAllPages();
      this.addPageModel = false;
      (this.page.title = ""),
        (this.page.path = ""),
        (this.page.html = "<h2>Add page content..</h2>"),
        (this.menu.name = ""),
        (this.menu.icon = ""),
        (this.menu.color = ""),
        (this.menu.parent_id = ""),
        (this.e1 = 1);
    },
    selectMenuForPage(menu) {
      this.mainMenu = menu;
      this.mainMenu.page = this.page.path;
      console.log(menu);
    },
    openPageCreateMenu() {
      this.addPageModel = true;
      (this.page.title = ""),
        (this.page.path = ""),
        (this.page.html = "<h2>Add page content..</h2>"),
        (this.e1 = 1),
        (this.menu.name = ""),
        (this.menu.icon = ""),
        (this.menu.color = ""),
        (this.menu.parent_id = ""),
        (this.menu.status = "publish");

      // this.subMenu.name = "",
      // this.subMenu.icon = "",
      // this.subMenu.color = "",
      // this.subMenu.page = "",
      // this.subMenu.parent_id = "",
      // this.subMenu.status = "",
    },
    toAddSubMenu() {
      if (this.mainMenu == "") {
        alert("please select a menu before finish page create function.");
      } else {
        console.log(this.mainMenu);
        delete this.mainMenu.parent_id;
        var data = {
          id: this.mainMenu.id,
          name: this.mainMenu.name,
          page: this.mainMenu.page,
          status: this.mainMenu.status,
          icon: this.mainMenu.icon,
        };
        if (this.mainMenu.children.length > 0) {
          for (var i = 0; i < this.mainMenu.children.length; i++) {
            console.log("im menus loop");

            if (this.page.path == this.mainMenu.children[i].page) {
              console.log(this.mainMenu.children[i]);
              this.selectedSubMenu = this.mainMenu.children[i];
            }
          }
        } else {
          this.selectedSubMenu = '';
        }

        this.updateMenu(data);
        this.e1 = 3;
      }
    },
    savePage() {
      if (this.page.title == "" || this.page.path == "") {
        alert("please add page title & path");
      } else {
        this.e1 = 2;
        console.log(this.page);

        // c1reate page
        if (this.page.id != null) {
          console.log("update page");

          for (var i = 0; i < this.menus.length; i++) {
            if (this.page.path == this.menus[i].page) {
              console.log("page == menu page");
              console.log(this.menus[i]);
              this.mainMenu = this.menus[i];
            }
          }

          this.pageUpdate(this.page);
        } else {
          this.createPage(this.page);
        }
      }
    },

    getPageContent(path) {
      this.callLoading(true);
      this.$http
        .get("viewPage/" + path)
        .then((res) => {
          this.response = res;
          this.content = res.body;
          this.callAlert(true, "success", "Contents loaded.");
          this.callLoading(false);
        })
        .catch((err) => {
          console.log(err.body);
          this.callLoading(false);
        });
    },
    updateEditedText() {
      this.callLoading(true);
      var data = this.content;

      // if (this.pageEdit == "help") {
      //   data = {
      //     id: 1,
      //     title: "Help page",
      //     path: "help_text_edit",
      //     html: this.content,
      //   };
      // } else {
      //   data = {
      //     id: 1,
      //     title: "Help page",
      //     path: "help_text_edit",
      //     html: this.content,
      //   };
      // }
      console.log(data);
      this.$http
        .post("updatePage", data)
        .then((res) => {
          console.log(res);
          // this.content = res.bodyText;
          this.$router.push("/dashboard");
          this.callAlert(true, "success", "Contents updated.");
          this.callLoading(false);
        })
        .catch((err) => {
          console.log(err.body);
          this.callLoading(false);
        });
    },
    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    updateCategory() {
      // this.callLoading(true);
      console.log(this.select);
      console.log(this.select.icon.icon);
      var data = {
        id: this.select.id,
        name: this.CateName,
        icon: this.select.icon.icon,
        color: this.colour,
      };
      this.$http
        .post("updateCategory", data)
        .then((res) => {
          console.log(res.body);
          this.addCateModal = false;
          this.callAlert(true, "success", "Category updated!");
          this.callLoading(false);
          this.getMenus();
        })
        .catch((err) => {
          this.callAlert(
            true,
            "error",
            "Category updated not success! Please try again"
          );
          this.callLoading(false);
          console.log(err.body);
        });
      console.log(data);
    },
    cancelCate() {
      (this.menu.name = ""),
        (this.menu.icon = ""),
        (this.menu.color = ""),
        (this.menu.parent_id = ""),
        (this.menuEdit = false);
      this.addMenuModal = false;
    },
    cancelMenu() {
      (this.menu.name = ""),
        (this.menu.icon = ""),
        (this.menu.color = ""),
        (this.menu.parent_id = ""),
        (this.menuEdit = false);
      this.addMenuModal = false;
    },
    cancelSubMenu() {
      this.addSubMenuModal = false;
      (this.subMenu.name = ""),
        (this.subMenu.icon = ""),
        (this.subMenu.color = ""),
        (this.subMenu.page = ""),
        (this.subMenu.parent_id = ""),
        (this.subMenu.status = ""),
        (this.subMenuEdit = false);
    },
    deleteMenu() {
      var data = {
        id: this.selectedMenuid,
      };
      this.$http
        .post("deleteMenu", data)
        .then((res) => {
          this.deleteConfirm = false;
          this.callAlert(true, "success", "Menu removed!");
          this.getMenus();
          this.savePage();
          this.toAddSubMenu();
          this.addPageModel = false;
          console.log(res.body);
        })
        .catch((err) => {
          this.callAlert(true, "error", "Menu remove error! Try again.");
          console.log(err.body);
        });
    },
    deletePage() {
      this.callLoading(true);
      var data = {
        id: this.selectedPageId,
      };
      this.$http
        .post("deletePage", data)
        .then((res) => {
          this.deleteConfirmPage = false;
          this.deleteConfirm = false;
          this.callAlert(true, "success", "Page removed!");
          this.getAllPages();
          console.log(res.body);
          this.callLoading(false);
        })
        .catch((err) => {
          this.callAlert(true, "error", "Page remove error! Try again.");
          console.log(err.body);
          this.callLoading(false);
        });
    },
    confirmDelete(id) {
      this.deleteConfirm = true;
      this.selectedMenuid = id;
    },

    confirmPageDelete(id) {
      this.deleteConfirmPage = true;
      this.selectedPageId = id;
    },
    editMenu(menu) {
      this.menuItemEdit = true;
      this.menu = menu;
      this.mainMenuIcon = menu.icon;
      this.addMenuModal = true;
    },
    editPage(page) {
      this.e1 = 1;
      console.log(page);

      // this.menuItemEdit = true;
      this.page = page;
      for (var i = 0; i < this.menus.length; i++) {
        console.log(this.menus[i]);
        this.mainMenu = this.menus[i];
      }
      this.addPageModel = true;

      console.log(this.select);
    },
    getselected(d) {
      console.log(d);
    },
    addSubMenu() {
      console.log("im sub menu name");
      var data = {
        name: this.subMenuName,
        icon: this.subMenuIcon.icon,
        page: this.page.path,
        parent_id: this.mainMenu.id,
        status: "publish",
      };

      console.log(data);
      this.createMenuSubMenu(data);
    },
    updateSubMenu() {
      alert("hlllo");
      // this.callLoading(true);
      if (this.copiedIcon == "") {
        this.subMenu.icon = this.subMenuIcon.icon;
      } else {
        this.subMenu.icon = this.copiedIcon;
      }

      this.subMenu.parent_id = this.mainMenu.id;

      this.subMenu.page = this.mainMenu.page;

      console.log("im page");
      console.log(this.page);
      console.log("im menu");
      console.log(this.menu);
      console.log("im sub menu");
      var data = this.subMenu;
      console.log(data);
      this.createMenuSubMenu(data);
    },
    addMenu() {
      console.log(this.page.path);
      console.log(this.menu);
      this.callLoading(true);
      if ((this.menu.page = this.page.path)) {
        console.log(this.menu);
        // check icon
        if (this.copiedIcon == "") {
          if (this.mainMenuIcon.icon == "") {
            alert("Please select an icon for this menu!");
          } else {
            this.menu.icon = this.mainMenuIcon.icon;
          }
        } else {
          this.menu.icon = this.copiedIcon;
        }

        var data = this.menu;
        console.log(data);

        if (this.page.parent_id == null) {
          delete data.parent_id;
          //  this.$delete(this.data, 'parent_id');
          console.log("im null");
          console.log(data);
        } else {
          console.log("im not null");
          console.log(data);
        }
        if (this.menuItemEdit) {
          this.updateMenu(data);
          this.menuItemEdit = false;
        } else {
          this.createMenuSubMenu(data);
        }
      }
    },
    getMenu(MenuId) {
      var data = {
        avoid_paginate: true,
        id: MenuId,
      };
      this.$http
        .post("viewMenu", data)
        .then((res) => {
          this.mainMenu = res.body;
        })
        .then((err) => {
          console.log(err);
        });
    },
    updateMenu(data) {
      console.log("im menu sub updater");
      console.log(data);
      this.$http
        .post("updateMenu", data)
        .then((res) => {
          console.log(res.body);
          (this.menu.name = ""),
            (this.menu.icon = ""),
            (this.menu.color = ""),
            (this.menu.parent_id = ""),
            (this.subMenu = "");
          // this.cancelCate();
          this.callAlert(true, "success", "Menu list updated!");
          this.callLoading(false);
          this.getMenus();
          this.addMenuModal = false;
          this.addSubMenuModal = false;
          this.$router.push({path:"/manage_pages"})
        })
        .catch((err) => {
          this.callAlert(true, "error", "Category add error! Please try again");
          this.callLoading(false);
          console.log(err.body.error);
        });
    },
    createMenuSubMenu(data) {
      console.log("im menu sub creater");
      console.log(data);
      this.$http
        .post("createMenu", data)
        .then((res) => {
          this.getMenus();

          console.log(res.body);
          (this.menu.name = ""),
            (this.menu.icon = ""),
            (this.menu.color = ""),
            (this.menu.parent_id = ""),
            (this.subMenu = "");
          // this.cancelCate();
          this.callAlert(true, "success", "New menu added!");
          this.callLoading(false);

          this.addMenuModal = false;
          this.addSubMenuModal = false;

          // this.selectMenuForPage(this.mainMenu);
        })
        .then((done) => {
          console.log(done);
          var id = this.mainMenu.id;
          console.log(this.mainMenu);
          this.getMenu(id);
        })
        .catch((err) => {
          this.callAlert(true, "error", "Category add error! Please try again");
          this.callLoading(false);
          console.log(err);
        });
    },
    openCategory(cate) {
      this.$store.selectedCate = cate;
      this.$store.moreSelectedcate.push(cate);
      this.$router.push("/cate_view");
    },
    selectSubForPage(sub) {
      this.selectedSubMenu = sub;
      this.selectedSubMenu.page = this.page.path;
      console.log(sub);
      // selectedSubMenu = sub
    },
  },

  components: {
    // pagination: Pagination,
    quillEditor,
  },

  computed: {
    pageEdit() {
      return this.$store.pageEdit;
    },

    pages() {
      return this.$store.pages;
    },
    menus() {
      return this.$store.menus;
    },
    loading() {
      return this.$store.loading;
    },
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
    windowHeight() {
      return this.$store.windowHeight;
    },
    alertModal() {
      return this.$store.alertModal;
    },

    totalCount() {
      return this.$store.totalCount;
    },
    selectedCate() {
      return this.$store.selectedCate;
    },
    categoryList() {
      return this.$store.categoryList;
    },
    cateIconList() {
      return this.$store.cateIconList;
    },
  },
  created() {
    // this.callLoading(true);
    this.getAllPages();
    this.getCategories(1);
    this.getMenus();
    // var resa = this.getAllPages();
    // if (resa != null) {
    //   this.callLoading(false);
    // }
  },
};
</script>
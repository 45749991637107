<template>
  <v-container fluid>
    <v-row class="text-center" :class="{ 'px-16': $vuetify.breakpoint.lgOnly }">
      <!-- :style="{ height: windowHeight - 100 + 'px' }" -->
      <v-col class="text-left mb-2 mt-4" cols="12" align-self="start">
        <h3 class="text-uppercase font-weight-medium subColorTxt">
          {{ selectedCate.name }}
          <!-- <pre>
             {{ selectedCate }}
          </pre> -->
        </h3>
        <!-- <p class="white--text">
          {{ selectedSubCate.name }}
        </p> -->
      </v-col>
      <v-col cols="12" md="3">
        <v-row>
          <v-col cols="12" class="mb-3">
            <v-card
              justify="center"
              class="borderR15 mainDarkColorBg"
              elevation="0"
            >
              <v-row>
                <v-col cols="12">
                  <v-btn
                    @click="addSubCateModal = true"
                    fab
                    x-small
                    class="subColorBg"
                    elevation="0"
                  >
                    <v-avatar size="18px">
                      <img src="./../assets/icon/Component 3.svg" alt="" />
                    </v-avatar>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            class="mb-3"
            v-for="sub in selectedCate.children"
            :key="sub.id"
          >
            <v-card
              @click="viewSubCate(sub)"
              class="borderR15 hoverClass whiteTxt mainDarkColorBg px-4"
              elevation="0"
              :class="{ activeClass: sub.id == selectedSubCate.id }"
            >
              <v-row align="center">
                <v-col cols="8" class="text-left">
                  <v-col cols="12">
                    <h3 class="font-weight-medium">
                      <v-btn icon outlined class="subColorTxt">
                        <v-icon medium>{{ sub.icon }}</v-icon>
                      </v-btn>

                      {{ sub.name }}
                    </h3>
                  </v-col>
                  <!-- <h4 class="font-weight-medium">
                    {{ sub.name }}
                  </h4> -->
                </v-col>
                <v-col cols="4" class="right">
                  <v-row>
                    <v-btn
                      @click="confirmDelete(sub)"
                      fab
                      x-small
                      class="red white--text mr-3"
                      elevation="0"
                    >
                      <v-icon>mdi-delete-forever</v-icon>
                    </v-btn>

                    <v-btn
                      @click="editSubCate(sub)"
                      fab
                      x-small
                      class="subColorBg"
                      elevation="0"
                    >
                      <v-avatar size="18px">
                        <img
                          src="./../assets/icon/Icon material-edit.svg"
                          alt=""
                        />
                      </v-avatar>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="text-left mainDarkColorBg pa-8" cols="12" md="9">
        <v-row class="ma-0 pa-0">
          <v-col cols="12" md="4">
            <v-text-field
              rounded
              dense
              flat
              label="Search / Filter"
              append-icon="mdi-search"
              placeholder="Name"
              v-model="searchName"
              solo
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <div class="subColorTxt text-uppercase">
              Total : {{ totalCount }}
            </div>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0">
          <v-col cols="12" md="4">
            <v-card
              justify="center"
              class="borderR15 text-center whiteBg pa-5"
              elevation="0"
            >
              <v-row>
                <v-col cols="12" @click="openDialog()">
                  <v-btn fab class="subColorBg pa-6" elevation="0">
                    <v-avatar size="30px">
                      <img src="./../assets/icon/Component 3.svg" alt="" />
                    </v-avatar>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="4"
            v-for="company in companies"
            :key="company.id"
          >
            <v-card class="whiteBg borderR15 py-3 px-4" elevation="0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <h3
                    class="font-weight-medium mb-2 text-uppercase text-truncate"
                  >
                    {{ company.name }}
                  </h3>

                  <p class="font-weight-medium f-18 text-truncate ma-0">
                    {{ company.description }}
                  </p>
                </v-col>
                <v-col class="text-right" cols="12">
                  <v-btn
                    @click="companyConfirmDelete(company)"
                    fab
                    x-small
                    class="red white--text mr-3"
                    elevation="0"
                  >
                    <v-icon>mdi-delete-forever</v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    x-small
                    class="subColorBg"
                    elevation="0"
                    @click="editCompany(company)"
                  >
                    <v-avatar size="18px">
                      <img
                        src="./../assets/icon/Icon material-edit.svg"
                        alt=""
                      />
                    </v-avatar>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <pagination v-bind:paginationFor="paginationFor"></pagination>
      </v-col>
    </v-row>
    <cu-company></cu-company>
    <v-row justify="center">
      <v-dialog v-model="addSubCateModal" persistent max-width="400px">
        <v-card class="pa-4">
          <v-row class="text-center">
            <v-col>
              <h1>
                <span v-if="editSubCateis"> Edit </span>
                <span v-else> Add </span>
                sub category
              </h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3 class="mb-3">This sub category for?</h3>
              <!-- <p>{{ radios || "null" }}</p> -->
              <v-radio-group v-model="radios" mandatory>
                <v-radio
                  color="#F5A623"
                  label="Web shops"
                  value="webshop"
                ></v-radio>
                <v-radio
                  color="#F5A623"
                  label="Not for web shops"
                  value="store"
                ></v-radio>
              </v-radio-group>
              <span v-if="radios == 'store'">
                <h3 class="mb-3">Name of sub category</h3>
                <v-text-field
                  dense
                  v-model="SubCateName"
                  flat
                  label="Name of sub category"
                  placeholder="abc"
                  solo
                >
                </v-text-field>
              </span>
            </v-col>
            <v-col cols="12">
              <h3>Select an icon from list</h3>
              <p class="mb-3 f10 grayTxt">
                (If icon not in this list, please copy an icon name from
                <a
                  target="_blank"
                  href="https://pictogrammers.github.io/@mdi/font/5.4.55/"
                  >Mdi-Icons</a
                >
                )
              </p>

              <v-combobox
                v-model="selectedIcon"
                :items="cateIconList"
                label="Select an icon"
                item-text="icon"
                item-value="item"
                solo
              >
                <template slot="item" slot-scope="data">
                  <v-icon class="mr-3">{{ data.item.icon }}</v-icon>
                  <span class="cb-item">{{ data.item.icon }}</span>
                </template>
              </v-combobox>
              <!-- <v-select  v-model="icon" flat item-text="name" :items="categoryList" label="Icon name" solo></v-select> -->
            </v-col>
            <v-col cols="12">
              <h4>Paste copied icon from web site</h4>
              <p class="mb-3 f10 grayTxt">
                (Leave blank if selected an icon from list above)
              </p>

              <v-text-field
                dense
                v-model="copiedIcon"
                flat
                label="Paste icon name here"
                placeholder="mdi-icon-name"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="subColorTxt" text @click="cancelSub()">
              cancel
            </v-btn>
            <v-btn
              v-if="editSubCateis"
              class="subColorBg whiteTxt"
              text
              @click="updateSubCate()"
            >
              update
            </v-btn>
            <v-btn
              v-else
              class="subColorBg whiteTxt"
              text
              @click="addSubCate()"
            >
              add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="deleteConfirm" max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            Do you want to delete this Sub category?
          </v-card-title>

          <v-card-text>
            When you click on 'DELETE' button this sub category and also created
            companies below this will permenantly removed from this app!
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="grey darken-1" text @click="deleteConfirm = false">
              cancel
            </v-btn>

            <v-btn color="red darken-1" text @click="deleteCate()">
              delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="companyDeleteConfirm" max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            Do you want to delete this Company?
          </v-card-title>

          <v-card-text>
            When you click on 'DELETE' button this Company will removed from
            this app!
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="grey darken-1"
              text
              @click="$store.companyDeleteConfirm = false"
            >
              cancel
            </v-btn>

            <v-btn color="red darken-1" text @click="deleteCompany()">
              delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import GlobalFunctions from "./../GlobalFunctions.js";
import CUCompany from "./modals/CreateEditCompany.vue";

import Pagination from "./sub_components/Pagination.vue";

export default {
  mixins: [GlobalFunctions],
  name: "LoginScreen",
  data: () => ({
    select: "",
    selectedIcon: "",
    copiedIcon: "",
    paginationFor: "cate_view",
    radios: false,
    searchName: "",
    addSubCateModal: false,
    SubCateName: "",
    editSubCateis: false,
    selectedSubCateId: "",
    deleteConfirm: false,
  }),
  created() {
    this.$store.moreSelectedSubcate = [];
    if (this.$store.moreSelectedcate[0].children.length > 0) {
      if (this.$store.moreSelectedSubcate.length == 0) {
        this.$store.moreSelectedSubcate.push(this.selectedCate.children[0]);
        this.viewSubCate(this.selectedCate.children[0]);
      }
    }
  },
  watch: {
    searchName: function () {
      this.getCompanies(this.selectedSubCate.id, this.searchName, 1);
    },
  },
  methods: {
    editCompany(company) {
      this.$store.companyEditEnable = true;
      this.$store.placeData = company;
      this.webAdd = company.url[0] ? company.url[0].url : "";
      this.instaAdd = company.url[1] ? company.url[1].url : "";
      this.fbAdd = company.url[2] ? company.url[2].url : "";
      this.addresses = company.address;
      this.$store.dialog = true;

      console.log(company);
    },

    deleteCate() {
      this.callLoading(true);
      var data = {
        id: this.selectedSubCateId,
      };
      this.$http
        .post("deleteCategory", data)
        .then((res) => {
          this.deleteConfirm = false;
          this.callAlert(true, "success", "Sub Category removed!");
          this.openCategory(this.selectedCate.id);
          console.log(res.body);
        })
        .catch((err) => {
          this.callAlert(true, "error", "Category remove error! Try again.");
          console.log(err.body);
          this.callLoading(false);
        });
    },
    confirmDelete(sub) {
      console.log(sub.name);
      this.deleteConfirm = true;
      this.selectedSubCateId = sub.id;
    },
    companyConfirmDelete(sub) {
      console.log(sub);
      this.$store.companyDeleteConfirm = true;
      this.$store.selectedCompany = sub;
    },
    cancelSub() {
      this.SubCateName = "";
      this.editSubCateis = false;
      this.addSubCateModal = false;
    },
    updateSubCate() {
      this.callLoading(true);
      var selectedIcon;
      // check icon
      if (this.copiedIcon == "") {
        if (this.selectedIcon == "") {
          alert("Please select an icon for this menu!");
        } else {
          selectedIcon = this.selectedIcon.icon;
        }
      } else {
        selectedIcon = this.copiedIcon;
      }
      var data = {
        name: this.radios == "store" ? this.SubCateName : "Webshop",
        id: this.selectedSubCateId,
        icon: selectedIcon,
        color: this.selectedCate.color,
      };
      this.$http
        .post("updateCategory", data)
        .then((res) => {
          console.log(res.body);
          this.addSubCateModal = false;
          this.callAlert(true, "success", "Sub category updated!");
          this.openCategory(this.selectedCate.id);
        })
        .catch((err) => {
          this.callAlert(
            true,
            "error",
            "Category updated not success! Please try again"
          );
          this.callLoading(false);
          console.log(err.body);
        });
      console.log(data);
    },
    viewSubCate(data) {
      this.$store.selectedSubCate = data;
      // this.$store.moreSelectedSubcate = data;
      console.log("im open", data);
      this.getCompanies(data.id, "", 1);
    },
    editSubCate(data) {
      this.editSubCateis = true;
      this.addSubCateModal = true;
      this.SubCateName = data.name;
      this.selectedSubCateId = data.id;
      this.select = data;
      this.selectedIcon = data.icon;
      console.log(data);
    },
    addSubCate() {
      this.callLoading(true);
      var selectedIcon;
      // check icon
      if (this.copiedIcon == "") {
        if (this.selectedIcon == "") {
          alert("Please select an icon for this menu!");
        } else {
          selectedIcon = this.selectedIcon.icon;
        }
      } else {
        selectedIcon = this.copiedIcon;
      }
      var data = {
        type: this.radios,
        name: this.radios == "store" ? this.SubCateName : "Webshop",
        parent_id: this.selectedCate.id,
        color: this.selectedCate.color,
        icon: selectedIcon,
      };
      console.log(data);
      this.$http
        .post("createCategory", data)
        .then((res) => {
          console.log(res.body);
          this.SubCateName = "";
          this.addSubCateModal = false;
          this.callAlert(true, "success", "New subcategory added!");
          this.openCategory(this.selectedCate.id);
        })
        .catch((err) => {
          this.callAlert(true, "error", "Category add error! Please try again");
          this.callLoading(false);
          console.log(err.body.error);
        });

      console.log(data);
    },
    openCategory(ids) {
      var data = {
        id: ids,
      };
      this.$http
        .post("viewCategory", data)
        .then((res) => {
          this.$store.selectedCate = res.body;
          console.log(res.body);
          this.callLoading(false);
          this.cancelSub();
        })
        .catch((err) => {
          console.log(err.body);
        });
    },
  },
  components: {
    "cu-company": CUCompany,
    pagination: Pagination,
  },
  computed: {
    cateIconList() {
      return this.$store.cateIconList;
    },
    categoryList() {
      //  return this.$store.categoryList ? this.items.slice(0, 3) : [];
      return this.$store.categoryList;
    },
    totalCount() {
      //  return this.$store.categoryList ? this.items.slice(0, 3) : [];
      return this.$store.totalCount;
    },
    placeData() {
      return this.$store.placeData;
    },
    moreSelectedcate() {
      return this.$store.moreSelectedcate;
    },
    dialog() {
      return this.$store.dialog;
    },
    moreSelectedSubcate() {
      return this.$store.moreSelectedSubcate;
    },
    windowHeight() {
      return this.$store.windowHeight;
    },
    companyDeleteConfirm() {
      return this.$store.companyDeleteConfirm;
    },
    selectedCompany() {
      return this.$store.selectedCompany;
    },
    selectedSubCate() {
      return this.$store.selectedSubCate;
    },

    loading() {
      return this.$store.loading;
    },
    companies() {
      return this.$store.companies;
    },
    selectedCate() {
      return this.$store.selectedCate;
    },
  },
};
</script>
<style>
</style>

<template>
  <v-container fluid>
    <v-row
      class="text-center"
      justify="center"
      :class="{ 'px-16': $vuetify.breakpoint.lgOnly }"
      :style="{ height: windowHeight + 'px' }"
    >
      <v-col cols="12" md="4" align-self="center">
        <v-card class="borderR15 pa-9" elevation="20">
          <v-row>
            <v-col cols="12" class="mb-4">
              <h2>Login</h2>
            </v-col>
            <v-col cols="12">
              <v-text-field
                rounded
                dense
                flat
                v-model="email"
                label="Enter valid email address"
                placeholder="abc@mail.com"
                solo
              ></v-text-field>
              <v-text-field
                rounded
                dense
                v-model="password"
                flat
                type="password"
                label="Enter password"
                placeholder="********"
                solo
              ></v-text-field>
              <v-btn
                @click="login()"
                block
                rounded
                x-large
                class="subColorBg whiteTxt"
                elevation="0"
                >login</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GlobalFunctions from "./../GlobalFunctions.js";
export default {
  mixins: [GlobalFunctions],
  name: "LoginScreen",

  data: () => ({
    email: "",
    password: "",
  }),
  computed: {
    windowHeight() {
      return this.$store.windowHeight;
    },
    loading() {
      return this.$store.loading;
    }
  },
  methods: {
    login() {
      this.callLoading(true)
      var data = {
        email: this.email,
        password: this.password,
      };
      this.$http
        .post("auth/login", data)
        .then((res) => {
          this.$auth.setToken(res.body.token);
          this.callAlert(true,'success','Welcome to Go Green admin!' )
          this.$router.push('/dashboard')
          console.log(res.body.token);
          this.callLoading(false)
        })
        .catch((err) => {
          this.callLoading(false)
           this.callAlert(true,'error','Email or password incorrect, Please try again' )
          console.log(err.body.error);
        });
    },
  },
};
</script>

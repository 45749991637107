<template>
  <v-container fluid>
    <v-row
      class="text-center"
      justify="center"
      :class="{ 'px-16': $vuetify.breakpoint.lgOnly }"
      :style="{ height: windowHeight - 100 + 'px' }"
    >
      <v-col class="text-left" cols="12" align-self="start">
        <h3 class="text-uppercase font-weight-medium subColorTxt">Dashboard</h3>
      </v-col>
      <v-col cols="12" md="6" align-self="center">
        <v-card class="borderR15 pa-5" elevation="20">
          <v-row>
            <v-col cols="12" class="">
              <h1>Welcome back to</h1>
            </v-col>
            <v-col cols="12">
              <v-avatar tile width="150px" height="auto">
                <img src="./../assets/logo.png" alt="" />
              </v-avatar>
            </v-col>
            <v-col cols="12" class="mb-4">
              <h1>Admin-panel</h1>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="borderR15 pa-5 mt-6">
          <v-row align="center">
            <v-col cols="10" class="text-left">
              <h3>
                <v-icon class="subColorTxt"> mdi-home-circle-outline</v-icon>
                Welcome page
              </h3>
            </v-col>
            <v-col cols="2" class="text-right">
              <v-btn
                fab
                @click="goEdit('welcome')"
                x-small
                class="subColorBg"
                elevation="0"
              >
                <v-avatar size="18px">
                  <img src="./../assets/icon/Icon material-edit.svg" alt="" />
                </v-avatar>
              </v-btn>
            </v-col>
          </v-row>

          <!-- <v-row align="center">
            <v-col cols="10" class="text-left">
              <h3>
                <v-icon class="subColorTxt"> mdi-help-circle-outline</v-icon>
                Help page
              </h3>
            </v-col>
            <v-col cols="2" class="text-right">
              <v-btn
                fab
                @click="goEdit('help')"
                x-small
                class="subColorBg"
                elevation="0"
              >
                <v-avatar size="18px">
                  <img src="./../assets/icon/Icon material-edit.svg" alt="" />
                </v-avatar>
              </v-btn>
            </v-col>
          </v-row> -->
          <v-row align="center">
            <v-col cols="10" class="text-left">
              <h3>
                <v-icon class="subColorTxt"> mdi-checkbook</v-icon>
                Manage pages & Menu
              </h3>
            </v-col>
            <v-col cols="2" class="text-right">
              <v-btn
                fab
                @click="goToPageManage()"
                x-small
                class="subColorBg"
                elevation="0"
              >
                <v-avatar size="18px">
                  <img src="./../assets/icon/Icon material-edit.svg" alt="" />
                </v-avatar>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LoginScreen",

  data: () => ({}),
  computed: {
    windowHeight() {
      return this.$store.windowHeight;
    },
    pageEdit() {
      return this.$store.pageEdit;
    },
    alertModal() {
      return this.$store.alertModal;
    },
  },
  methods: {
    goEdit(edit) {
      if ((this.$store.pageEdit = edit)) {
        this.$router.push("/help_text_edit");
      }
    },
    goToPageManage() {
      this.$router.push("/manage_pages");
    },
  },
  created() {
    this.checkAuth();
  },
};
</script>
